
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import { TrackingModule } from '@/store'

const DISABLED = !!window.Cypress || !!window.__PRERENDER_INJECTED

@Component({
  name: 'TrackingBanner'
})
export default class extends Vue {
  trackingModule = getModule(TrackingModule, this.$store)

  visible = false

  created () {
    if (!DISABLED) {
      this.visible = this.trackingModule.enabled === null
    }
  }

  accept () {
    this.trackingModule.setTracking(true)
    this.visible = false
  }

  reject () {
    this.trackingModule.setTracking(false)
    this.visible = false
  }
}
