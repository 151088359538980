
import { OrganizationBillingCurrency, OrganizationBillingCycle, OrganizationPlan } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Aurora from '@/components/aurora.vue'
import { getDemoLinks } from '@/helpers/demoLinks'
import { TrackingModule } from '@/store'

import IsolationCalculatorDialog from '../components/isolation-calculator-dialog.vue'
import * as analytics from '../helpers/analytics'
import { CURRENCIES } from '../helpers/currencies'

const CHECK_ICON = {
  icon: '$fas-check',
  iconColor: 'grey lighten-2'
} as const

const CROSS_ICON = {
  icon: '$custom-solid-horizontal-rule',
  iconColor: 'grey lighten-4'
} as const

const UNLIMITED_TEXT = {
  text: 'Unlimited'
} as const

const PILOT_PRICES: Record<OrganizationBillingCurrency, number> = {
  cad: 1360,
  eur: 900,
  gbp: 800,
  usd: 1000
}

const GROWTH_PRICES: Record<NonNullable<OrganizationBillingCycle>, Record<OrganizationBillingCurrency, number>> = {
  annually: {
    cad: 55,
    eur: 36,
    gbp: 32,
    usd: 40
  },
  monthly: {
    cad: 68,
    eur: 45,
    gbp: 40,
    usd: 50
  }
}

const GROWTH_PRICES_AFTER_THREASHOLD: Record<NonNullable<OrganizationBillingCycle>, Record<OrganizationBillingCurrency, number>> = {
  annually: {
    cad: 16,
    eur: 11,
    gbp: 10,
    usd: 12
  },
  monthly: {
    cad: 20,
    eur: 13,
    gbp: 11,
    usd: 14
  }
}

@Component({
  components: {
    Aurora,
    IsolationCalculatorDialog
  },
  metaInfo: {
    meta: [
      { content: 'miscommunication,save,time,money,prevent,mistakes,hours,meetings,tech,technology,software,architecture,agile,team,waste', name: 'keywords', vmid: 'keywords' },
      { content: 'https://icepanel.io/pricing', name: 'og:url', vmid: 'og:url' },
      { content: 'IcePanel | Pricing | A fraction of the cost of miscommunication', name: 'og:title', vmid: 'og:title' },
      { content: 'IcePanel | Pricing | A fraction of the cost of miscommunication', name: 'twitter:title', vmid: 'twitter:title' },
      { content: 'A fraction of the cost of miscommunication. Get everyone on the same page, without wasting countless hours in meetings.', name: 'description', vmid: 'description' },
      { content: 'A fraction of the cost of miscommunication. Get everyone on the same page, without wasting countless hours in meetings.', name: 'og:description', vmid: 'og:description' },
      { content: 'A fraction of the cost of miscommunication. Get everyone on the same page, without wasting countless hours in meetings.', name: 'twitter:description', vmid: 'twitter:description' }
    ],
    title: 'IcePanel | Pricing | A fraction of the cost of miscommunication'
  },
  name: 'Pricing'
})
export default class extends Vue {
  trackingModule = getModule(TrackingModule, this.$store)

  @Ref() readonly isolationCalculatorDialogRef!: IsolationCalculatorDialog

  billingCurrency: OrganizationBillingCurrency = localStorage.getItem('billingCurrency') as OrganizationBillingCurrency | null || 'usd'
  billingCycle: OrganizationBillingCycle = 'annually'

  selectedPlan: OrganizationPlan = 'growth'

  seatCount = 1
  seatCountModel = 1

  growthSeatCountMin = 1
  growthSeatCountMax = 100
  growthDiscountThreshold = 15

  get demoLink () {
    return getDemoLinks()
  }

  get localeString () {
    switch (this.billingCurrency) {
      case 'cad':
        return 'en-CA'
      case 'eur':
        return 'de-DE'
      case 'gbp':
        return 'en-GB'
      case 'usd':
        return 'en-US'
      default:
        return 'en-US'
    }
  }

  get getGrowthPlanDiscountPerUser () {
    return GROWTH_PRICES_AFTER_THREASHOLD[this.billingCycle][this.billingCurrency].toLocaleString(this.localeString, {
      currency: this.billingCurrency,
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
      style: 'currency'
    })
  }

  get getGrowthPlanPriceWithoutDiscount () {
    const growthPriceWithoutDiscount = GROWTH_PRICES[this.billingCycle][this.billingCurrency] * this.seatCount
    return growthPriceWithoutDiscount.toLocaleString(this.localeString, {
      currency: this.billingCurrency,
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
      style: 'currency'
    })
  }

  get getGrowthPlanSavings () {
    const growthPriceWithoutDiscount = GROWTH_PRICES[this.billingCycle][this.billingCurrency] * this.seatCount
    const growthPrice = GROWTH_PRICES[this.billingCycle][this.billingCurrency] * this.growthDiscountThreshold
    const growthDiscountPrice = GROWTH_PRICES_AFTER_THREASHOLD[this.billingCycle][this.billingCurrency] * (this.seatCount - this.growthDiscountThreshold)
    return (growthPriceWithoutDiscount - (growthPrice + growthDiscountPrice)).toLocaleString(this.localeString, {
      currency: this.billingCurrency,
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
      style: 'currency'
    })
  }

  get getGrowthPlanUserCountDiscountPriceTotal () {
    if (this.seatCount > this.growthDiscountThreshold) {
      // more than 15 seats selected so return the normal price for the first 15 and then the discounted price for the remaining seats
      const growthPrice = GROWTH_PRICES[this.billingCycle][this.billingCurrency] * this.growthDiscountThreshold
      const growthDiscountPrice = GROWTH_PRICES_AFTER_THREASHOLD[this.billingCycle][this.billingCurrency] * (this.seatCount - this.growthDiscountThreshold)
      return (growthPrice + growthDiscountPrice).toLocaleString(this.localeString, {
        currency: this.billingCurrency,
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
        style: 'currency'
      })
    } else {
      // less than 15 seats selected so just return the normal price
      const growthPrice = GROWTH_PRICES[this.billingCycle][this.billingCurrency] * this.seatCount
      return growthPrice.toLocaleString(this.localeString, {
        currency: this.billingCurrency,
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
        style: 'currency'
      })
    }
  }

  get getPilotPlanPriceTotal () {
    return PILOT_PRICES[this.billingCurrency].toLocaleString(this.localeString, {
      currency: this.billingCurrency,
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
      style: 'currency'
    })
  }

  get appUrl () {
    return `https://app.icepanel.${process.env.VUE_APP_ICE_ENV === 'production' ? 'io' : 'dev'}`
  }

  get currencies () {
    return CURRENCIES
  }

  get currentCurrency () {
    return CURRENCIES.find(o => o.id === this.billingCurrency)!
  }

  get selectedPlans () {
    if (this.$vuetify.breakpoint.smAndDown) {
      return this.plans.filter(o => o.id === this.selectedPlan)
    } else {
      return this.plans
    }
  }

  get plans () {
    return [
      {
        actionPrimary: {
          click: () => this.getStartedLink('top'),
          href: `${this.appUrl}/user/sign-up?plan=free&billing_cycle=${this.billingCycle}&billing_currency=${this.billingCurrency}`,
          icon: '$fas-user-edit',
          text: 'Get started free'
        },
        caption: 'Start modelling in one team',
        id: 'free',
        price: {
          caption: '/ month',
          symbol: this.currentCurrency.symbol,
          value: 0
        },
        seats: {
          max: 5,
          min: 1
        },
        summary: {
          caption: 'Free includes:',
          list: [
            {
              text: 'C4 modelling & diagrams',
              tooltip: 'Zoom in and out of the C4 model diagram levels, easily exploring the big picture to the details.'
            },
            {
              text: 'Up to 100 model objects',
              tooltip: 'Create up to 100 objects inside your model and draw them as many times as needed.'
            },
            {
              text: 'Public share links',
              tooltip: 'Share and embed public interactive read-only public links of your landscape and diagrams.'
            },
            {
              text: 'Unlimited share viewers',
              tooltip: 'Unlimited read-only users can access share links of your landscape, diagrams and flows.'
            }
          ]
        },
        text: 'Free'
      },
      {
        actionPrimary: {
          click: () => this.bookDemoLink('top', 'growth'),
          href: 'https://icepanel.pipedrive.com/scheduler/ppow9KTpp/demo-intro',
          icon: '$custom-solid-bullseye-pointer',
          text: 'Book demo | 15 mins'
        },
        actionSecondary: this.$vuetify.breakpoint.lgAndUp
          ? {
              click: () => analytics.getStartedLink.track(this, {
                getStartedPosition: 'top'
              }),
              href: `${this.appUrl}?plan=growth&billing_cycle=${this.billingCycle}&billing_currency=${this.billingCurrency}&seats=${this.seatCount}`,
              text: 'Get started free'
            }
          : undefined,
        caption: 'Collaborate on large models across multiple teams',
        glow: true,
        id: 'growth',
        price: {
          caption: '/ month',
          symbol: this.currentCurrency.symbol,
          value: GROWTH_PRICES[this.billingCycle][this.billingCurrency]
        },
        seats: {
          max: this.growthSeatCountMax,
          min: this.growthSeatCountMin
        },
        summary: {
          caption: 'Everything in Free, plus:',
          list: [
            {
              text: 'Unlimited modelling',
              tooltip: 'No limits on landscapes, model objects, tags, flows, reality links and versions.'
            },
            {
              text: 'Domains for large models',
              tooltip: 'Domains organize your landscape\'s systems into logical groups aligned with your business structure. You can share objects in diagrams between domains..'
            },
            {
              text: 'Diagram groups',
              tooltip: 'Groups for organizing many diagrams by subject.'
            },
            {
              text: 'Assign object owners',
              tooltip: 'Share who your system experts are by assigning owners to model objects, and contact them easily.'
            },
            {
              text: 'Object versioning',
              tooltip: 'Manage the lifecycle of each domain, system, app and store independently with hierarchical versioning.'
            },
            {
              text: 'Protected share links',
              tooltip: 'Share and embed password or SSO protected interactive read-only links of your landscape and diagrams.'
            },
            {
              text: 'Single sign-on',
              tooltip: 'Manage your team’s log in authentication with SAML 2.0 SSO.'
            },
            {
              text: 'Premium support',
              tooltip: 'Email & Slack < 48 hours'
            }
          ]
        },
        text: 'Growth'
      },
      {
        actionPrimary: {
          click: () => this.isolationCalculatorDialogRef.open(),
          icon: '$fas-calculator',
          text: 'Calculate price'
        },
        actionSecondary: {
          click: () => this.bookDemoLink('top', 'isolation'),
          href: 'https://icepanel.pipedrive.com/scheduler/ppow9KTpp/demo-intro',
          text: 'Book a demo'
        },
        caption: 'Single-tenant environment for custom data security',
        id: 'isolation',
        price: {
          caption: 'Custom pricing'
        },
        seats: {
          min: 50
        },
        summary: {
          caption: 'Everything in Growth, plus:',
          list: [
            {
              text: 'Data residency option',
              tooltip: 'Choose a custom Google Cloud region for your environment and data storage.'
            },
            {
              text: 'IP allow/block list',
              tooltip: 'Restrict access of your IcePanel environment to specific IP range or corporate network.'
            },
            {
              text: 'Environment audit logs',
              tooltip: 'Receive full database, authentication and environment-wide audit logs.'
            },
            {
              text: 'Extra security parameters',
              tooltip: 'We will accommodate extra security parameters you need to configure.'
            },
            {
              text: 'Customer success program',
              tooltip: 'Dedicated customer success manager to upskill your team to ensure you get the most out of the tool.'
            },
            {
              text: 'Priority support',
              tooltip: 'Email, Slack & Phone < 24 hours'
            }
          ]
        },
        text: 'Isolation'
      }
    ] as const
  }

  get features () {
    return [
      {
        icon: '$fas-sitemap',
        id: 'modelling',
        list: [
          {
            description: 'Zoom in and out of the C4 model diagram levels, easily exploring the big picture to the details.',
            image: require('../assets/features/zoom.gif'),
            imageAspectRatio: 1.777,
            plans: {
              free: {
                text: '100 model objects'
              },
              growth: UNLIMITED_TEXT,
              isolation: UNLIMITED_TEXT
            },
            text: 'Model and diagrams'
          },
          {
            description: 'A landscape uses a single model to draw many diagrams; visualizing the systems spanning across your organization.',
            image: require('../assets/features/landscape.webp'),
            imageAspectRatio: 1.777,
            plans: {
              free: {
                text: 'Up to 2'
              },
              growth: {
                text: 'Unlimited'
              },
              isolation: {
                text: 'Unlimited'
              }
            },
            text: 'Landscapes'
          },
          {
            description: 'Groups for organizing many diagrams by subject.',
            image: require('../assets/features/diagram-groups.gif'),
            imageAspectRatio: 1.839,
            plans: {
              free: CROSS_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Diagram groups'
          },
          {
            description: 'Domains organize your landscape\'s systems into logical groups aligned with your business structure. You can share objects in diagrams between domains.',
            image: require('../assets/features/domains.gif'),
            imageAspectRatio: 1.777,
            plans: {
              free: CROSS_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Domains'
          },
          {
            description: 'Design future versions of your architecture in isolation and merge the changes to into the current model once approved.',
            image: require('../assets/features/draft-diagram.gif'),
            imageAspectRatio: 1.847,
            plans: {
              free: {
                text: '1 draft per diagram'
              },
              growth: {
                text: 'Unlimited'
              },
              isolation: {
                text: 'Unlimited'
              }
            },
            text: 'Draft designs'
          },
          {
            description: 'Attach rich documentation to model objects, connections and diagrams to give more context to your audience - supports Markdown.',
            image: require('../assets/features/documentation.gif'),
            imageAspectRatio: 1.777,
            plans: {
              free: CHECK_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Documentation'
          },
          {
            description: 'Updates to your model push change across all diagrams, making design refactoring effortless.',
            image: require('../assets/features/sync-model-changes.gif'),
            imageAspectRatio: 1.777,
            plans: {
              free: CHECK_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Sync model changes'
          },
          {
            description: 'Visually follow the evolution of your system design across time.',
            image: require('../assets/features/versions.gif'),
            imageAspectRatio: 1.777,
            plans: {
              free: {
                text: 'View last 3 versions'
              },
              growth: UNLIMITED_TEXT,
              isolation: UNLIMITED_TEXT
            },
            text: 'Versions'
          },
          {
            description: 'Revert back to a point in the past with version reverting.',
            image: require('../assets/features/version-revert.gif'),
            imageAspectRatio: 1.777,
            plans: {
              free: CROSS_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Version revert'
          },
          {
            description: 'Manage the lifecycle of each domain, system, app and store independently with hierarchical versioning.',
            image: require('../assets/features/version-objects.gif'),
            imageAspectRatio: 1.777,
            plans: {
              free: CROSS_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Versions for objects'
          },
          {
            description: 'Export your objects, connections, diagrams, flows, tags and teams in PNG, PDF, JSON or CSV formats',
            image: require('../assets/features/exports.webp'),
            plans: {
              free: CHECK_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Exports'
          }
        ],
        text: 'Modelling'
      },
      {
        icon: '$fas-layer-group',
        id: 'visual-storytelling',
        list: [
          {
            description: 'Dynamically overlay different perspectives on your existing diagrams and filter your model by tags.',
            image: require('../assets/features/tags.gif'),
            imageAspectRatio: 1.777,
            plans: {
              free: {
                text: '2 tag groups'
              },
              growth: UNLIMITED_TEXT,
              isolation: UNLIMITED_TEXT
            },
            text: 'Tags'
          },
          {
            description: 'Dynamically overlay sequences of messages on existing diagrams to showcase technical, user or business flows.',
            image: require('../assets/features/flows.gif'),
            imageAspectRatio: 1.777,
            plans: {
              free: {
                text: '3 flows'
              },
              growth: UNLIMITED_TEXT,
              isolation: UNLIMITED_TEXT
            },
            text: 'Flows'
          },
          {
            description: 'Show alternate and parallel choices in a message flow',
            image: require('../assets/features/flow-paths.gif'),
            imageAspectRatio: 1.895,
            plans: {
              free: CROSS_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Flow paths'
          },
          {
            description: 'Add AWS, Azure, GCP and Kubernetes icons to visualize the technology choice of your apps and stores.',
            image: require('../assets/features/cloud-icons.webp'),
            imageAspectRatio: 1.777,
            plans: {
              free: CHECK_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Cloud icons'
          },
          {
            description: 'Visualize the differences between versions of your system design and easily follow the evolution across time.',
            image: require('../assets/features/versions.gif'),
            imageAspectRatio: 1.777,
            plans: {
              free: CHECK_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Version timeline'
          }
        ],
        text: 'Visual storytelling'
      },
      {
        icon: '$fas-calendar-check',
        id: 'up-to-date-checks',
        list: [
          {
            description: 'List important resources in your source control and quickly get to them - great for onboarding. We periodically check reality links and mark them as inaccurate if missing.',
            image: require('../assets/features/reality-links.gif'),
            imageAspectRatio: 1.777,
            plans: {
              free: {
                text: '10 links - 1 per object'
              },
              growth: UNLIMITED_TEXT,
              isolation: UNLIMITED_TEXT
            },
            text: 'Links'
          },
          {
            description: 'Link to important resources in your source control and quickly get to them - great for onboarding. We periodically check these links and mark them as inaccurate if missing.\n\nSupports: GitHub, GitLab, BitBucket Server and Azure DevOps',
            icons: [
              '$fab-github',
              '$fab-gitlab',
              '$fab-bitbucket',
              '$fab-windows'
            ],
            image: require('../assets/features/code-repo-integration.webp'),
            imageAspectRatio: 1.777,
            plans: {
              free: CHECK_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Code repo integrations'
          },
          {
            description: 'See where your design drifts from reality and take action to resolve the inaccuracies.',
            image: require('../assets/features/accuracy-score.webp'),
            imageAspectRatio: 1.777,
            plans: {
              free: CHECK_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Inaccuracy score'
          },
          {
            description: 'See where your documentation could be improved and resolve common diagramming mistakes.',
            image: require('../assets/features/jpeg/recommendation-score.jpg'),
            imageAspectRatio: 1.777,
            plans: {
              free: CHECK_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Recommendation score'
          }
        ],
        text: 'Up-to-date checks'
      },
      {
        icon: '$fas-users',
        id: 'collaboration',
        list: [
          {
            description: 'Collaborate with your teammates and create a consistent shared knowledge base - better together.',
            image: require('../assets/features/collaboration.gif'),
            imageAspectRatio: 1.776,
            plans: {
              free: {
                text: '1 - 5'
              },
              growth: {
                text: '1 - 100'
              },
              isolation: {
                text: '50+'
              }
            },
            text: 'Editors'
          },
          {
            description: 'Collaborate with your teammates and create a consistent shared knowledge base - better together.',
            image: require('../assets/features/collaboration.gif'),
            imageAspectRatio: 1.776,
            plans: {
              free: {
                text: 'Unlimited'
              },
              growth: {
                text: 'Unlimited'
              },
              isolation: {
                text: 'Unlimited'
              }
            },
            text: 'Viewers'
          },
          {
            description: 'Share and embed interactive read-only links of your landscape and diagrams.',
            image: require('../assets/features/share-links.gif'),
            imageAspectRatio: 1.777,
            plans: {
              free: {
                text: 'Public'
              },
              growth: {
                text: 'Password + SSO'
              },
              isolation: {
                text: 'Password + SSO'
              }
            },
            text: 'Share links'
          },
          {
            description: 'Share interactive frozen versions of your design as it existed in the past.',
            image: require('../assets/features/share-link-versions.gif'),
            imageAspectRatio: 1.777,
            plans: {
              free: CROSS_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Share links for versions'
          },
          {
            description: 'Share who your system experts are by assigning owners to model objects and contact them easily.',
            image: require('../assets/features/teams.gif'),
            imageAspectRatio: 1.777,
            plans: {
              free: CROSS_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Team ownership for objects'
          },
          {
            description: 'See a detailed logs of the changes happening in your landscape.',
            image: require('../assets/features/history-logs.gif'),
            imageAspectRatio: 1.777,
            plans: {
              free: {
                text: 'Last 30 days'
              },
              growth: UNLIMITED_TEXT,
              isolation: UNLIMITED_TEXT
            },
            text: 'History logs'
          },
          {
            description: 'Collaborate in real time with your colleagues.',
            image: require('../assets/features/collaboration.gif'),
            imageAspectRatio: 1.776,
            plans: {
              free: CHECK_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Real time'
          }
        ],
        text: 'Collaboration'
      },
      {
        icon: '$fas-hand-holding-heart',
        id: 'support',
        list: [
          {
            description: 'Contact us with queries, feedback and support requests. Our virtual door is always open.',
            plans: {
              free: {
                text: 'Email'
              },
              growth: {
                text: 'Email & Slack'
              },
              isolation: {
                text: 'Email, Slack & Phone'
              }
            },
            text: 'Support channels'
          },
          {
            description: 'We respond to your queries, feedback and support requests as soon as possible.',
            plans: {
              free: {
                text: '< 5 days'
              },
              growth: {
                text: '< 48 hours'
              },
              isolation: {
                text: '< 24 hours'
              }
            },
            text: 'Response time'
          },
          {
            description: 'Manage your team\'s logins with SAML 2.0 single sign-on.',
            plans: {
              free: CROSS_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Single sign-on'
          },
          {
            description: 'Automatically add users registering with your company domain to your organization .',
            plans: {
              free: CROSS_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Company email auto invite'
          },
          {
            description: 'Flexible payment options with annual invoice billing.',
            plans: {
              free: CROSS_ICON,
              growth: {
                text: 'For 10+ editors'
              },
              isolation: CHECK_ICON
            },
            text: 'Invoice billing'
          },
          {
            description: 'Master services agreement (MSA) customized to your legal and technical requirements.',
            plans: {
              free: CROSS_ICON,
              growth: CROSS_ICON,
              isolation: CHECK_ICON
            },
            text: 'Master services agreement'
          },
          {
            description: 'Choose a custom Google Cloud region for your environment and data storage.',
            plans: {
              free: CROSS_ICON,
              growth: CROSS_ICON,
              isolation: CHECK_ICON
            },
            text: 'Data residency option'
          },
          {
            description: 'Restrict access of your IcePanel environment to specific IP range or corporate network.',
            plans: {
              free: CROSS_ICON,
              growth: CROSS_ICON,
              isolation: CHECK_ICON
            },
            text: 'IP allow/block list'
          },
          {
            description: 'Receive full database, authentication and environment-wide audit logs.',
            plans: {
              free: CROSS_ICON,
              growth: CROSS_ICON,
              isolation: CHECK_ICON
            },
            text: 'Environment audit logs'
          },
          {
            description: 'We will accommodate extra security parameters you need to configure.',
            plans: {
              free: CROSS_ICON,
              growth: CROSS_ICON,
              isolation: CHECK_ICON
            },
            text: 'Extra security parameters'
          },
          {
            description: 'Dedicated customer success manager to upskill your team to ensure you get the most out of the tool.',
            plans: {
              free: CROSS_ICON,
              growth: CROSS_ICON,
              isolation: CHECK_ICON
            },
            text: 'Customer success program'
          }
        ],
        text: 'Support'
      }
    ]
  }

  get pilotTimeline () {
    return [
      {
        caption: 'Session 1',
        color: '#F2F6F8',
        colorEnd: '#CB80F0',
        icon: '$custom-duotone-user-chart',
        items: [
          'C4 model training/refresher',
          'IcePanel getting started training',
          'Advanced feature training'
        ],
        title: 'Training'
      },
      {
        caption: 'Session 2',
        color: '#CB80F0',
        colorEnd: '#588AF7',
        icon: '$custom-duotone-clipboard-list-check',
        items: [
          'Model and diagram reviews',
          'Tips for improvements'
        ],
        title: 'Review'
      },
      {
        caption: 'Session 3',
        color: '#588AF7',
        colorEnd: '#8CECFF',
        footnote: 'Highly customizable',
        icon: '$custom-duotone-users-class',
        items: [
          'Collaborative modelling session'
        ],
        title: 'Workshop'
      },
      {
        caption: 'Session 4',
        color: '#8CECFF',
        colorEnd: '#0DBF6F',
        icon: '$custom-duotone-file-certificate',
        items: [
          'Final top tips for improvements',
          'Advice moving forward',
          'Review your pilot program'
        ],
        title: 'Final review'
      }
    ]
  }

  get tracking () {
    return this.trackingModule.enabled
  }

  @Watch('tracking')
  onTrackingChanged (tracking: boolean, prevTracking: boolean) {
    if (tracking && !prevTracking) {
      analytics.pricingScreen.track(this)
    }
  }

  @Watch('billingCurrency')
  onBillingCurrencyChanged (billingCurrency: OrganizationBillingCurrency) {
    localStorage.setItem('billingCurrency', billingCurrency)
    this.isolationCalculatorDialogRef.billingCurrency = billingCurrency
  }

  mounted () {
    analytics.pricingScreen.track(this)
  }

  validateSeatCount (updateModel: boolean) {
    this.seatCount = Math.max(this.growthSeatCountMin, Math.min(this.growthSeatCountMax, this.seatCountModel))

    if (updateModel) {
      this.seatCountModel = this.seatCount
    }
  }

  getStartedLink (getStartedPosition: 'top' | 'bottom') {
    analytics.getStartedLink.track(this, {
      getStartedPosition
    })
  }

  bookDemoLink (bookDemoPosition: 'top' | 'bottom', bookDemoPlan?: OrganizationPlan) {
    analytics.bookDemoLink.track(this, {
      bookDemoPlan,
      bookDemoPosition
    })
  }

  pilotPurchaseLink () {
    analytics.pilotPurchaseLink.track(this)
  }
}
