
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import * as analytics from '@/helpers/analytics'
import { TrackingModule } from '@/store'

@Component({
  metaInfo: {
    meta: [

      { content: 'nothing,empty', name: 'keywords', vmid: 'keywords' },
      { content: 'IcePanel | 404 | Explain complex software systems', name: 'og:title', vmid: 'og:title' },
      { content: 'IcePanel | 404 | Explain complex software systems', name: 'twitter:title', vmid: 'twitter:title' },
      { content: 'Nothing to see here. System design for your whole team', name: 'description', vmid: 'description' },
      { content: 'Nothing to see here. System design for your whole team', name: 'og:description', vmid: 'og:description' },
      { content: 'Nothing to see here. System design for your whole team', name: 'twitter:description', vmid: 'twitter:description' }
    ],
    title: 'IcePanel | 404 | Explain complex software systems'
  },
  name: 'PageNotFound'
})
export default class extends Vue {
  trackingModule = getModule(TrackingModule, this.$store)

  get tracking () {
    return this.trackingModule.enabled
  }

  @Watch('tracking')
  onTrackingChanged (tracking: boolean, prevTracking: boolean) {
    if (tracking && !prevTracking) {
      analytics.pageNotFoundScreen.track(this)
    }
  }

  mounted () {
    analytics.pageNotFoundScreen.track(this)
  }

  get image () {
    return Math.random() > 0.5 ? require('../assets/404/puddle.webp') : require('../assets/404/lost.webp')
  }
}
