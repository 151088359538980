export interface NavigationItem {
  href?: string
  link?: { name: string }
  target?: string
  text: string
  description?: string
  icon?: string,
  subItems?: NavigationItem[]
  subItemsCTA?: NavigationItem
}

export const navigationItems: NavigationItem[] = [
  {
    link: {
      name: 'features'
    },
    text: 'Features'
  },
  {
    link: {
      name: 'learn'
    },
    text: 'Learn'
  },
  {
    link: {
      name: 'pricing'
    },
    text: 'Pricing'
  },
  {
    subItems: [
      {
        description: 'IcePanel documentation',
        href: 'https://docs.icepanel.io/',
        icon: '$custom-duotone-books',
        text: 'Docs'
      },
      {
        description: 'Get started with the C4 model',
        icon: '$custom-duotone-layer-group',
        link: {
          name: 'c4-model'
        },
        text: 'C4 Model'
      },
      {
        description: 'Latest IcePanel posts',
        icon: '$custom-duotone-newspaper',
        link: {
          name: 'blog'
        },
        text: 'Blog'
      },
      {
        description: 'IcePanel roadmap',
        icon: '$custom-solid-signs-post',
        link: {
          name: 'roadmap'
        },
        text: 'Roadmap'
      },
      {
        description: 'SOC II, GDPR, Pen test, sub processors',
        icon: '$custom-duotone-shield',
        link: {
          name: 'security'
        },
        text: 'Security'
      }
    ],
    subItemsCTA: {
      href: 'https://book.stripe.com/bIYbJ65xz3ioaYw5kq',
      icon: '$fas-graduation-cap',
      text: 'Book training'
    },
    text: 'Resources'
  },
  {
    link: {
      name: 'contact'
    },
    text: 'Contact'
  }
]
