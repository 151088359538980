
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import { NavigationItem } from '../helpers/navigation-items'

@Component({
  name: 'NavBarDropdown'
})
export default class extends Vue {
  @Prop() tab!: NavigationItem
  @Prop({ type: Boolean }) isLowerBar!: boolean

 expandedTab = false

 goTo (tab: NavigationItem) {
   if (tab.link) {
     this.$router.push({ name: tab.link.name })
   } else if (tab.href) {
     window.open(tab.href, tab.target || '_self')
   }
 }
}
