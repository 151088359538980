
import Vue from 'vue'
import Component from 'vue-class-component'

import Footer from './components/footer.vue'
import NavBar from './components/navbar/navbar.vue'
import TrackingBanner from './components/tracking-banner.vue'

@Component({
  components: {
    Footer,
    NavBar,
    TrackingBanner
  },
  metaInfo: {
    meta: [
      { content: 'c4,model,onboard,engineers,explorable,system,design,complex,software,architecture,lightweight,visual,approach,linked,code', name: 'keywords', vmid: 'keywords' },
      { content: 'https://icepanel.io/', name: 'og:url', vmid: 'og:url' },
      { content: 'IcePanel', name: 'og:site_name', vmid: 'og:site_name' },
      { content: 'IcePanel Technologies Inc.', name: 'author', vmid: 'author' },
      { content: '/social-media.png', name: 'image', property: 'og:image', vmid: 'og:image' },
      { content: '1200', name: 'image', property: 'og:image:width', vmid: 'og:image:width' },
      { content: '630', name: 'image', property: 'og:image:height', vmid: 'og:image:height' },
      { content: 'IcePanel | Explain complex software systems', name: 'og:title', vmid: 'og:title' },
      { content: 'Explain complex software systems', name: 'twitter:title', vmid: 'twitter:title' },
      { content: 'IcePanel is a collaborative diagramming tool that helps software engineering and product teams align on technical decisions. Create an interactive map of your software systems and give your teams full context about how things work.', name: 'description', vmid: 'description' },
      { content: 'IcePanel is a collaborative diagramming tool that helps software engineering and product teams align on technical decisions. Create an interactive map of your software systems and give your teams full context about how things work.', name: 'og:description', vmid: 'og:description' },
      { content: 'IcePanel is a collaborative diagramming tool that helps software engineering and product teams align on technical decisions. Create an interactive map of your software systems and give your teams full context about how things work.', name: 'twitter:description', vmid: 'twitter:description' }],
    title: 'IcePanel | Explain complex software systems'
  },
  name: 'App'
})
export default class extends Vue {
  loaded = false

  mounted () {
    setTimeout(() => {
      this.loaded = true

      setTimeout(() => {
        document.dispatchEvent(new Event('render-trigger'))
      }, 500)
    }, 200)
  }
}
