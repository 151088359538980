
import Vue from 'vue'
import Component from 'vue-class-component'

import * as analytics from '../../../helpers/analytics'

@Component({
  name: 'NavBarLoginButton'
})
export default class extends Vue {
  get appUrl () {
    return `https://app.icepanel.${process.env.VUE_APP_ICE_ENV === 'production' ? 'io' : 'dev'}`
  }

  logInLink () {
    analytics.logInLink.track(this)
  }
}
