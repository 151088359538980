import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import customIconValues from './custom-icons'
import iconValues from './icons'

Vue.component('FontAwesomeIcon', FontAwesomeIcon)

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'faSvg',
    values: {
      ...iconValues,
      ...customIconValues
    }
  },
  theme: {
    dark: true,
    options: {
      customProperties: true
    },
    themes: {
      dark: {
        accent: '#8CECFF',
        anchor: '#8CECFF',
        error: '#E85E65',
        info: '#F5B841',
        primary: '#323435',
        secondary: '#004E8E',
        success: '#09814A',
        warning: '#FF8811'
      }
    }
  }
})
