
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { SupportModule, TrackingModule } from '@/store'

import * as analytics from '../../helpers/analytics'

const defaultModel = {
  autoInvite: false,
  certificate: '',
  companyDomain: '',
  companyEmailInvite: false,
  email: '',
  entityId: '',
  permission: false,
  ssoUrl: ''
}

@Component({
  metaInfo: {
    meta: [
      { content: 'saml,sso,registration,entity,certificate,company,single,sign,on,setup', name: 'keywords', vmid: 'keywords' },
      { content: 'https://icepanel.io/sso-registration/saml', name: 'og:url', vmid: 'og:url' },
      { content: 'IcePanel | SSO Registration | SAML', name: 'og:title', vmid: 'og:title' },
      { content: 'IcePanel | SSO Registration | SAML', name: 'twitter:title', vmid: 'twitter:title' },
      { content: 'We just need a few things from you to set up your company with SAML single sign-on.', name: 'og:description', vmid: 'og:description' },
      { content: 'We just need a few things from you to set up your company with SAML single sign-on.', name: 'description', vmid: 'description' },
      { content: 'We just need a few things from you to set up your company with SAML single sign-on.', name: 'twitter:description', vmid: 'twitter:description' }
    ],
    title: 'IcePanel | SSO Registration | SAML'
  },
  name: 'SSORegistrationSAML'
})
export default class extends Vue {
  supportModule = getModule(SupportModule, this.$store)
  trackingModule = getModule(TrackingModule, this.$store)

  @Ref() readonly form!: any

  model = { ...defaultModel }

  isSubmitting = false
  submitError: string | null = null
  submitSuccess = false

  get docsUrl () {
    return 'https://docs.icepanel.io'
  }

  get tracking () {
    return this.trackingModule.enabled
  }

  @Watch('tracking')
  onTrackingChanged (tracking: boolean, prevTracking: boolean) {
    if (tracking && !prevTracking) {
      analytics.ssoRegistrationSamlScreen.track(this)
    }
  }

  mounted () {
    analytics.ssoRegistrationSamlScreen.track(this)
  }

  async submit () {
    if (this.submitSuccess) {
      return
    }

    if (!this.form.validate()) {
      return
    }

    this.submitError = null
    this.submitSuccess = false
    this.isSubmitting = true

    try {
      await this.supportModule.supportMessageCreate({
        body: {
          autoInvite: this.model.autoInvite,
          certificate: this.model.certificate,
          companyDomain: this.model.companyDomain,
          entityId: this.model.entityId,
          ssoUrl: this.model.ssoUrl,
          type: 'sso-saml-registration'
        },
        email: this.model.email
      })

      analytics.ssoRegistrationSaml.track(this)

      this.submitSuccess = true

      this.form.resetValidation()
      this.model = { ...defaultModel }
    } catch (err: any) {
      this.submitError = err.body ? err.body.message : err.message
      throw err
    } finally {
      this.isSubmitting = false
    }
  }
}
