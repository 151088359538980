export default [{
  name: 'UTM IDE Plugin Facebook (Experiment 1)',
  path: '/l/ce19538b',
  redirect: {
    name: 'solution',
    query: {
      utm_campaign: 'IDE_Plugin',
      utm_medium: 'social_media_post',
      utm_source: 'facebook'
    }
  }
}, {
  name: 'UTM VSCode Marketplace Download Discord Facebook (Experiment 2)',
  path: '/l/a5ac0c6b',
  redirect: {
    name: 'solution',
    query: {
      utm_campaign: 'VSCode_Marketplace_Download_E2',
      utm_medium: 'social_media_post',
      utm_source: 'facebook'
    }
  }
}, {
  name: 'UTM Homepage Facebook (Experiment 3)',
  path: '/l/0HUGNpbyR',
  redirect: {
    name: 'solution',
    query: {
      utm_campaign: 'VSCode_Homepage_E3',
      utm_medium: 'social_media_post',
      utm_source: 'facebook'
    }
  }
}, {
  name: 'UTM Homepage Facebook (Experiment 4)',
  path: '/l/lCPnXU8jb',
  redirect: {
    name: 'solution',
    query: {
      utm_campaign: 'VSCode_Homepage_E4',
      utm_medium: 'social_media_post',
      utm_source: 'facebook'
    }
  }
}, {
  name: 'UTM Desktop App Facebook (Experiment 5)',
  path: '/l/bMMXMSPyD',
  redirect: {
    name: 'solution',
    query: {
      utm_campaign: 'DesktopApp_E5',
      utm_medium: 'social_media_post',
      utm_source: 'facebook'
    }
  }
}]
