/* eslint-disable import/no-webpack-loader-syntax */
import changelog from 'raw-loader!../../../../CHANGELOG.md'
import remarkGfm from 'remark-gfm'
import markdown from 'remark-parse'
import unified from 'unified'

import { Node } from '@/types/markdown'

export interface IChangelogVersion {
  added: string[]
  changed: string[]
  date: string
  description: string[]
  fixed: string[]
  image?: string
  name: string
  title?: string
}

const processor = unified().use(remarkGfm).use(markdown)
const tree = processor.parse(changelog) as Node

const versions: IChangelogVersion[] = []

let curVersion: string | undefined
let curSection: 'description' | 'added' | 'changed' | 'fixed' | undefined

tree.children.forEach((l1: { children: any[] }) => {
  l1.children?.forEach(l2 => {
    const version = versions.find(o => o.name === curVersion)

    const l2Value = (l2 as any).value as string
    const l2Url = (l2 as any).url as string | undefined

    const l2Groups = /\[(?<version>\d+.\d+)\]\W-\W(?<date>\d+\/\d+\/\d+\W\d+:\d+:\d+\W\w+-\d+)(\W-\W(?<title>.*))?/g.exec(l2Value)?.groups

    if (l2Groups) {
      versions.push({
        added: [],
        changed: [],
        date: l2Groups.date,
        description: [],
        fixed: [],
        name: l2Groups.version,
        title: l2Groups.title
      })
      curVersion = l2Groups.version
      curSection = 'description'
    } else if (l2Value === 'Added') {
      curSection = 'added'
    } else if (l2Value === 'Fixes' || l2Value === 'Fixed') {
      curSection = 'fixed'
    } else if (l2Value === 'Changes' || l2Value === 'Changed') {
      curSection = 'changed'
    } else if (l2Url && version && curSection === 'description') {
      version.image = l2Url
    } else if (l2Value && version && curSection === 'description') {
      version.description.push(l2Value)
    }

    l2.children?.forEach((l3: { children: any[] }) => {
      l3.children?.forEach(l4 => {
        const l4Value = (l4 as any).value as string
        if (l4Value && version && curSection && curSection !== 'description') {
          version[curSection].push(l4Value)
        }
      })
    })
  })
})

export default versions
