import { OpenAPI } from '@icepanel/platform-api-client'
import * as mixpanel from 'mixpanel-browser'

const MIXPANEL_HEADER = 'distinct-id'
const DEFAULT_TOKEN = 'c8ac28a418eb7559e4c2ed4408b52db4'

export const headers = () => ({
  [MIXPANEL_HEADER]: mixpanel.get_distinct_id()
})

mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN || DEFAULT_TOKEN, {
  batch_flush_interval_ms: 1000,
  batch_requests: true,
  cross_subdomain_cookie: true,
  debug: process.env.VUE_APP_ICE_ENV === 'dev',
  loaded: () => {
    OpenAPI.HEADERS = {
      ...OpenAPI.HEADERS,
      ...headers()
    }
  },
  opt_out_persistence_by_default: true,
  opt_out_tracking_by_default: true
})

const disabled = window.Cypress || window.__PRERENDER_INJECTED
if (disabled) {
  mixpanel.opt_out_tracking()
}

export const optIn = () => mixpanel.opt_in_tracking()

export const optOut = () => mixpanel.opt_out_tracking()

export const identify = (userId: string) => {
  mixpanel.identify(userId)

  OpenAPI.HEADERS = {
    ...OpenAPI.HEADERS,
    ...headers()
  }
}

export const reset = () => {
  mixpanel.reset()

  OpenAPI.HEADERS = {
    ...OpenAPI.HEADERS,
    [MIXPANEL_HEADER]: ''
  }
}

window.addEventListener('unload', () => {
  if (!disabled) {
    mixpanel.set_config({
      api_transport: 'sendBeacon'
    })
  }
})
