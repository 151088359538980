
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Aurora from '@/components/aurora.vue'
import CustomerQuotes from '@/components/customer-quotes.vue'
import Iceberg from '@/components/iceberg.vue'
import Mountain from '@/components/mountain.vue'
import Demo from '@/components/sections/demo.vue'
import Examples from '@/components/sections/examples.vue'
import TrustedBy from '@/components/trusted-by.vue'
import Video from '@/components/video.vue'
import { getDemoLinks } from '@/helpers/demoLinks'
import { TrackingModule } from '@/store'

import * as analytics from '../helpers/analytics'

@Component({
  components: {
    Aurora,
    CustomerQuotes,
    Demo,
    Examples,
    Iceberg,
    Mountain,
    TrustedBy,
    Video
  },
  metaInfo: {
    meta: [
      { content: 'design,visual,c4,model,diagram,tool,share,system,architecture,team,interactive,version,control,code,diagram,perspective,zoom,sequence,flow', name: 'keywords', vmid: 'keywords' },
      { content: 'https://icepanel.io/', name: 'og:url', vmid: 'og:url' },
      { content: 'IcePanel | The C4 model collaboration tool', name: 'og:title', vmid: 'og:title' },
      { content: 'IcePanel | The C4 model collaboration tool', name: 'twitter:title', vmid: 'twitter:title' },
      { content: 'IcePanel is a collaborative diagramming tool that helps software engineering and product teams align on technical decisions. Create an interactive map of your software systems and give your teams full context about how things work.', name: 'description', vmid: 'description' },
      { content: 'IcePanel is a collaborative diagramming tool that helps software engineering and product teams align on technical decisions. Create an interactive map of your software systems and give your teams full context about how things work.', name: 'og:description', vmid: 'og:description' },
      { content: 'IcePanel is a collaborative diagramming tool that helps software engineering and product teams align on technical decisions. Create an interactive map of your software systems and give your teams full context about how things work.', name: 'twitter:description', vmid: 'twitter:description' }
    ],
    title: 'IcePanel | The C4 model collaboration tool'
  },
  name: 'C4Model'
})
export default class extends Vue {
  trackingModule = getModule(TrackingModule, this.$store)

  get appUrl () {
    return `https://app.icepanel.${process.env.VUE_APP_ICE_ENV === 'production' ? 'io' : 'dev'}`
  }

  get demoLink () {
    return getDemoLinks()
  }

  get design () {
    return [
      'Visually design interactive C4 model diagrams with your team',
      'Incremental and future architecture with a consistent language'
    ]
  }

  get explain () {
    return [
      'C4 levels of detail in a tool accessible to your whole team',
      'Visually share knowledge with perspectives for each conversation'
    ]
  }

  get maintain () {
    return [
      'Link your design to reality and be notified when it’s out of date',
      'Sync model updates across your C4 diagrams for living docs'
    ]
  }

  get interactiveDiagrams () {
    return [
      'Navigate through C4 levels of detail for multiple audiences',
      'Model reusable relationships between objects',
      'Understand your systems dependencies',
      'Sync your updates across all diagrams',
      'Design current and future architectures'
    ]
  }

  get visualOverlays () {
    return [
      'Multiple perspectives, without diagram duplication',
      'Interactive messages flowing through your system(s)',
      'Give colours meaning - showing multiple perspectives',
      'Visualize the evolution of your design decisions',
      'Speed up onboarding and knowledge transfer without handholding'
    ]
  }

  get realityLinking () {
    return [
      ['Link your model to reality', '- Your source control'],
      'Identify and resolve inaccuracies',
      'Assign owners for accountability',
      'Health check scores of your design - driving updates'
    ]
  }

  get inclusiveTooling () {
    return [
      'Low barrier of entry - no need to learn a DSL just to diagram',
      'Share self-updating diagrams, removing stale artefacts',
      'Collaborate in real-time',
      'See and easily contact your system experts'
    ]
  }

  get tracking () {
    return this.trackingModule.enabled
  }

  @Watch('tracking')
  onTrackingChanged (tracking: boolean, prevTracking: boolean) {
    if (tracking && !prevTracking) {
      analytics.c4ModelScreen.track(this)
    }
  }

  mounted () {
    analytics.c4ModelScreen.track(this)
  }

  getStartedLink (getStartedPosition: 'top' | 'bottom') {
    analytics.getStartedLink.track(this, {
      getStartedPosition
    })
  }

  bookDemoLink (bookDemoPosition: 'top' | 'bottom') {
    analytics.bookDemoLink.track(this, {
      bookDemoPosition
    })
  }

  interactiveExampleLink (interactiveExamplePosition: 'top' | 'bottom') {
    analytics.interactiveExampleLink.track(this, {
      interactiveExamplePosition
    })
  }

  c4modelVideoPlay () {
    analytics.c4modelVideoPlay.track(this)
  }

  c4modelVideoEnd () {
    analytics.c4modelVideoEnd.track(this)
  }
}
