
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
  name: 'PostAuthorAvatar'
})
export default class extends Vue {
  @Prop() readonly author!: 'Victor' | 'Jacob' | 'Tim'

  get src () {
    switch (this.author) {
      case 'Victor': return require('@/assets/victor.png')
      case 'Jacob': return require('@/assets/jacob.png')
      case 'Tim': return require('@/assets/tim.png')
      default: return require('@/assets/jacob.png')
    }
  }
}
