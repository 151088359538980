
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Astronaut from '@/components/astronaut.vue'
import Aurora from '@/components/aurora.vue'
import Mountain from '@/components/mountain.vue'
import { getDemoLinks } from '@/helpers/demoLinks'
import { TrackingModule } from '@/store'

import * as analytics from '../helpers/analytics'

@Component({
  components: {
    Astronaut,
    Aurora,
    Mountain
  },
  metaInfo: {
    meta: [
      { content: 'system,design,software,architecture,visibility,alignment,agility,itteration,agile,solutions,language,diagrams,out-of-date,upfront,modelling,c4,collaboration', name: 'keywords', vmid: 'keywords' },
      { content: 'https://icepanel.io/learn', name: 'og:url', vmid: 'og:url' },
      { content: 'IcePanel | Get started with the C4 model', name: 'og:title', vmid: 'og:title' },
      { content: 'IcePanel | Get started with the C4 model', name: 'twitter:title', vmid: 'twitter:title' },
      { content: 'A lightweight structure to communicate technical designs across agile teams. Align on technical decisions across your software engineering and product teams.', name: 'description', vmid: 'description' },
      { content: 'A lightweight structure to communicate technical designs across agile teams. Align on technical decisions across your software engineering and product teams.', name: 'og:description', vmid: 'og:description' },
      { content: 'A lightweight structure to communicate technical designs across agile teams. Align on technical decisions across your software engineering and product teams.', name: 'twitter:description', vmid: 'twitter:description' }
    ],
    title: 'IcePanel | Get started with the C4 model'
  },
  name: 'Learn'
})
export default class extends Vue {
  trackingModule = getModule(TrackingModule, this.$store)

  currentQuote = 0

  get appUrl () {
    return `https://app.icepanel.${process.env.VUE_APP_ICE_ENV === 'production' ? 'io' : 'dev'}`
  }

  get demoLink () {
    return getDemoLinks()
  }

  get tracking () {
    return this.trackingModule.enabled
  }

  get byteByteGoAd () {
    return this.$route.query.utm_source === 'byte_byte_go'
  }

  get headlines () {
    return [
      {
        icon: '$custom-duotone-bullseye-arrow',
        text: 'Team visibility and alignment'
      },
      {
        icon: '$custom-duotone-bolt',
        text: 'Fuel agility and iterate faster'
      },
      {
        icon: '$custom-duotone-rocket-launch',
        text: 'Build better solutions'
      }
    ]
  }

  get problems () {
    return [
      {
        heading: 'Inconsistent language',
        icon: '$custom-duotone-language',
        text: 'Visual and verbal communication without structure or consistency is ineffective at telling the right message and results in confusion.'
      },
      {
        heading: 'Out-of-date diagrams',
        icon: '$custom-duotone-calendar-times',
        text: 'Diagrams stored across many generic drawing tools naturally result in chaotic and quickly outdated information.'
      },
      {
        heading: 'No upfront design',
        icon: '$custom-duotone-drafting-compass',
        text: 'Many believe that being agile means no system design is needed and this often results in a big ball of mud architecture.'
      },
      {
        heading: 'Detail without context',
        icon: '$custom-duotone-search-minus',
        text: 'Talking about the low level details without understanding the higher level context means important information is often missed.'
      }
    ]
  }

  get solutions () {
    return [
      {
        heading: 'Modelling',
        icon: '$custom-duotone-shapes',
        text: 'Modelling tools provide a single source of truth for long-term documentation and have reusable objects that keep all your diagrams up-to-date.'
      },
      {
        heading: 'Structure',
        icon: '$custom-duotone-layer-group',
        text: 'The C4 model is a lightweight layered approach that brings consistency to visual & verbal language about systems design; designed for technical and non-technical audiences.'
      },
      {
        heading: 'Collaboration',
        icon: '$custom-duotone-handshake',
        text: 'Collaboration on architecture across multiple teams bridges the gap between product and engineering; reducing wasted time and enabling agility.'
      }
    ]
  }

  get quotes () {
    return [
      require('../assets/quotes/tweet-1.webp'),
      require('../assets/quotes/tweet-2.webp'),
      require('../assets/quotes/tweet-3.webp')
    ]
  }

  get benefits () {
    return [
      {
        heading: 'Fuel agility and iterate faster',
        icon: '$custom-duotone-bolt',
        text: 'Alignment of technical decisions and proposed solutions enable teams to learn easier, iterate quicker and make better-informed decisions.'
      },
      {
        heading: 'Build better solutions',
        icon: '$custom-duotone-rocket-launch',
        text: 'Iteration creates better solutions with robust architectures that seamlessly scale and evolve with your organization regardless of size.'
      },
      {
        heading: 'Full visibility and alignment',
        icon: '$custom-duotone-bullseye-arrow',
        text: 'A complete shared understanding of your system architecture enables consistency and alignment across product and engineering teams.'
      }
    ]
  }

  get articles () {
    return [
      {
        href: 'http://u.icepanel.io/0e8f906d',
        image: require('@/assets/blog/people-around-table.webp'),
        subtitle: 'The C4 model is a simple way to diagram software architecture and consists of just 2 things',
        title: 'Visualizing software architecture with the C4 model'
      },
      {
        href: 'http://u.icepanel.io/000a2262',
        image: require('@/assets/blog/blueprint.webp'),
        subtitle: 'When should you choose to diagram or model your software architecture?',
        title: 'Modelling vs diagramming software architecture'
      },
      {
        href: 'http://u.icepanel.io/f127bc38',
        image: require('@/assets/blog/what-is-a-system.webp'),
        subtitle: 'When starting your C4 model journey, a common question is “what are our systems?”... here’s how you work that out',
        title: 'C4 model - how to define your system'
      }
    ]
  }

  @Watch('tracking')
  onTrackingChanged (tracking: boolean, prevTracking: boolean) {
    if (tracking && !prevTracking) {
      analytics.learnScreen.track(this)
    }
  }

  mounted () {
    analytics.learnScreen.track(this)
  }

  getStartedLink (getStartedPosition: 'top' | 'bottom') {
    analytics.getStartedLink.track(this, {
      getStartedPosition
    })
  }

  bookDemoLink (bookDemoPosition: 'top' | 'bottom') {
    analytics.bookDemoLink.track(this, {
      bookDemoPosition
    })
  }

  learnVideoPlay () {
    analytics.learnVideoPlay.track(this)
  }

  learnVideoEnd () {
    analytics.learnVideoEnd.track(this)
  }
}
