<template>
  <Container
    class="tw-flex tw-flex-col justify-content-center py-12"
    style="z-index: 1; margin-top: 80px; margin-bottom: 60px"
  >
    <v-row>
      <v-col
        cols="12"
        class="tw-mx-auto"
        :style="`${$vuetify.breakpoint.lgAndUp ? 'max-width: 740px;' : ''}`"
      >
        <div class="tw-flex tw-flex-col">
          <router-link
            :to="{name: 'blog'}"
            class="tw-mb-4 body-2"
          >
            <div class="tw-flex">
              <v-icon
                color="accent"
                small
                class="tw-mr-2"
                v-text="'$fas-chevron-left'"
              />
              See all posts
            </div>
          </router-link>
          <p class="tw-mb-4">
            {{ new Date(post.metadata.date).toDateString() }}
          </p>
          <h1 class="text-h2 font-weight-light tw-mb-4">
            {{ post.metadata.title }}
          </h1>
          <p class="body-3 tw-mb-4">
            {{ post.metadata.summary }}
          </p>
          <div class="tw-my-4 tw-flex tw-items-center tw-flex-row tw-mb-8">
            <PostAuthorAvatar
              class="tw-mr-4"
              :author="post.metadata.author"
            />
            <p class="ma-0 pa-0">
              {{ post.metadata.author }}
            </p>
          </div>
          <img
            :src="post.metadata.featuredimg"
            class="tw-mb-12 tw-mr-4"
            style="width:100%; border-radius:12px;"
          >
        </div>

        <vue-markdown
          class="post"
          :anchor-attributes="{
            target: '_blank',
            rel: 'noopener noreferrer nofollow'
          }"
          :source="post.content"
        />
      </v-col>
    </v-row>
  </Container>
</template>

<script>
import * as mixpanel from 'mixpanel-browser'
import VueMarkdown from 'vue-markdown'
import { mapState } from 'vuex'

import PostAuthorAvatar from '@/components/post-author-avatar.vue'

import posts from '../../../posts/posts.json'

const parseName = (pathname) => {
  return pathname
    .toLowerCase()
    .replace('/index.html', '')
    .replace('index.html', '')
    .replace(/^\//, '')
    .replace(/\/$/, '')
    .replace(/\//, '_')
    .replace(/-/g, '_')
    .replace(/,/g, '')
    .replace(/ +/g, '_')
    .replace(/___/g, '_')
    .replace(/__/g, '_')
}

export default {
  components: {
    PostAuthorAvatar,
    VueMarkdown
  },
  data () {
    return {
      post: posts.find(post => post.name === this.$route.name)
    }
  },
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  computed: {
    ...mapState({
      tracking: state => state.tracking.enabled
    })
  },
  metaInfo () {
    return {
      link: [
        {
          href: `https://icepanel.io/blog/${this.post.name}`, rel: 'canonical'
        }
      ],
      meta: [
        // url
        { content: `https://icepanel.io/blog/${this.post.name}`, name: 'og:url', vmid: 'og:url' },

        // title
        { content: `${this.post.metadata.title} | IcePanel Blog`, name: 'og:title', vmid: 'og:title' },
        { content: `${this.post.metadata.title} | IcePanel Blog`, name: 'twitter:title', vmid: 'twitter:title' },

        // descriptions
        { content: this.post.metadata.summary, name: 'description', vmid: 'description' },
        { content: this.post.metadata.summary, name: 'og:description', vmid: 'og:description' },
        { content: this.post.metadata.summary, name: 'twitter:description', vmid: 'twitter:description' },

        // keywords
        { content: this.post.metadata.tags, name: 'keywords', vmid: 'keywords' },

        // image
        { content: this.post.metadata.featuredimg, name: 'image', property: 'og:image', vmid: 'og:image' },
        { content: '1200', name: 'image', property: 'og:image:width', vmid: 'og:image:width' },
        { content: '630', name: 'image', property: 'og:image:height', vmid: 'og:image:height' },

        // article specific data
        { content: 'article', name: 'og:type', vmid: 'og:type' },
        { content: 'Technology', property: 'article:section', vmid: 'article:section' },
        { content: `${this.post.metadata.author} @ IcePanel Technologies Inc.`, name: 'author', vmid: 'author' },
        { content: `${this.post.metadata.author} @ IcePanel Technologies Inc.`, name: 'article:author', vmid: 'article:author' },
        { content: this.post.metadata.date, property: 'article:published_time', vmid: 'article:published_time' },
        { content: this.post.metadata.date, property: 'article:modified_time', vmid: 'article:modified_time' },
        { content: this.post.metadata.tags, name: 'article:tag', vmid: 'article:tag' }
      ],
      script: [
        {
          json: {
            '@context': 'http://schema.org/',
            '@type': 'Article',
            author: {
              '@type': 'Person',
              name: this.post.metadata.author
            },
            datePublished: this.post.metadata.date,
            headline: this.post.metadata.title,
            image: this.post.metadata.featuredimg,
            publisher: {
              '@type': 'Organization',
              logo: {
                '@type': 'ImageObject',
                url: `https://icepanel.io${require('@/assets/logo-full.svg')}`
              },
              name: 'IcePanel Technologies Inc.'
            }
          },
          type: 'application/ld+json'
        }
      ],
      title: `${this.post.metadata.title} | IcePanel Blog`
    }
  },
  watch: {
    tracking (tracking, prevTracking) {
      if (tracking && !prevTracking) {
        mixpanel.track(`blog_post_${parseName(this.post.metadata.title)}`, {
          author: this.post.metadata.author,
          date: this.post.metadata.date,
          tags: this.post.metadata.tags,
          title: this.post.metadata.title
        })
      }
    }
  },
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  mounted () {
    mixpanel.track(`blog_post_${parseName(this.post.metadata.title)}`, {
      author: this.post.metadata.author,
      date: this.post.metadata.date,
      tags: this.post.metadata.tags,
      title: this.post.metadata.title
    })
  }
}
</script>

<style lang="scss">
.post img {
  max-width: 100%;
}

.post h2 {
  margin-bottom: 1rem;
  font-size: 1.75rem;
  font-weight: bold;
}

.post h3 {
  margin-bottom: .75rem;
  font-size: 1.5rem;
}

.post p,
.post ul,
.post ol,
.post pre {
  font-size: 1.25rem;
  margin-bottom: 2rem;
  list-style-type: disc;
}
.post pre {
  white-space: pre-wrap;
}

</style>
