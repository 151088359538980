import Vue from 'vue'
import Vuex from 'vuex'

import { INewsModule, NewsModule } from './news'
import { ISupportModule, SupportModule } from './support'
import { ITrackingModule, TrackingModule } from './tracking'

Vue.use(Vuex)

export interface IStore {
  support: ISupportModule
  news: INewsModule
  tracking: ITrackingModule
}

const store = new Vuex.Store<IStore>({})

store.registerModule(NewsModule.namespace, NewsModule)
store.registerModule(SupportModule.namespace, SupportModule)
store.registerModule(TrackingModule.namespace, TrackingModule)

export default store

export * from './news'
export * from './support'
export * from './tracking'
