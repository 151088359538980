
import Vue from 'vue'
import Component from 'vue-class-component'

@Component({
  name: 'CustomerQuotes'
})
export default class extends Vue {
  quotes = [
    {
      headline: 'Increased sales due to dev team efficiency',
      height: 40,
      logo: require('../assets/case-studies/logo-seer-interactive.png'),
      role: 'Data Engineer',
      text: 'A big value add with IcePanel has been documenting existing systems because inefficiencies and poor patterns jump out at you!',
      to: {
        name: 'case-study:seer-interactive'
      },
      width: 80
    },
    {
      headline: 'Better visibility between product & engineering',
      height: 40,
      logo: require('../assets/case-studies/logo-alphabet.png'),
      role: 'Application Architect',
      text: 'All stakeholders now easier digest the complexity of our landscape.',
      to: {
        name: 'case-study:alphabet'
      },
      width: 180
    },
    {
      headline: 'Central resource for architecture decisions',
      height: 40,
      logo: require('../assets/case-studies/logo-energy-web.png'),
      role: 'Senior Solution Owner',
      text: 'We absolutely fell in love with IcePanel 😀 finally we can do cross team collaboration with ease.',
      to: {
        name: 'case-study:energy-web'
      },
      width: 120
    }
  ]
}
