import { OrganizationPlan } from '@icepanel/platform-api-client'

import * as analytics from '@/analytics'

/**
 * Properties
 */

export const link: analytics.Property<string> = {
  displayName: 'Website link',
  name: 'website_link',
  required: true,
  type: 'string'
}
export const getStartedPosition: analytics.Property<'top' | 'bottom' | undefined> = {
  displayName: 'Website get started position',
  examples: ['top', 'bottom'],
  name: 'website_get_started_position',
  required: false,
  type: 'string'
}
export const seeInActionType: analytics.Property<'model' | 'tags' | 'flows' | 'reality-linking'> = {
  displayName: 'Website see in action type',
  examples: ['tags', 'flows', 'model', 'reality-linking'],
  name: 'website_see_in_action_type',
  required: true,
  type: 'string'
}
export const videoType: analytics.Property<'modelling' | 'c4-model'> = {
  displayName: 'Website see in action type',
  examples: ['modelling', 'modelling'],
  name: 'website_see_in_action_type',
  required: true,
  type: 'string'
}
export const messageFeeling: analytics.Property<string | undefined> = {
  displayName: 'Website message feeling',
  name: 'website_message_feeling',
  required: false,
  type: 'string'
}
export const messageBody: analytics.Property<string> = {
  displayName: 'Website message body',
  name: 'website_message_body',
  required: true,
  type: 'string'
}
export const messageLength: analytics.Property<number> = {
  displayName: 'Website message length',
  name: 'website_message_length',
  required: true,
  type: 'number'
}
export const dataRequestType: analytics.Property<string> = {
  displayName: 'Website data request type',
  name: 'website_data_request_type',
  required: true,
  type: 'string'
}
export const bookDemoPosition: analytics.Property<'top' | 'bottom' | undefined> = {
  displayName: 'Website book demo position',
  examples: ['top', 'bottom'],
  name: 'website_book_demo_position',
  required: false,
  type: 'string'
}
export const bookDemoPlan: analytics.Property<OrganizationPlan | undefined> = {
  displayName: 'Website book demo plan',
  name: 'website_book_demo_plan',
  required: false,
  type: 'string'
}
export const interactiveExamplePosition: analytics.Property<'top' | 'bottom' | undefined> = {
  displayName: 'Website interactive example position',
  examples: ['top', 'bottom'],
  hidden: true,
  name: 'website_interactive_example_position',
  required: false,
  type: 'string'
}
export const blogPosition: analytics.Property<'top' | 'bottom' | undefined> = {
  displayName: 'Website blog position',
  examples: ['top', 'bottom'],
  name: 'website_blog_position',
  required: false,
  type: 'string'
}
export const docsPosition: analytics.Property<'top' | 'bottom' | undefined> = {
  displayName: 'Website docs position',
  examples: ['top', 'bottom'],
  name: 'website_docs_position',
  required: false,
  type: 'string'
}
export const upgradePlan: analytics.Property<OrganizationPlan> = {
  displayName: 'Website upgrade plan',
  name: 'website_upgrade_plan',
  required: true,
  type: 'string'
}
export const roadmapRequestId: analytics.Property<number> = {
  displayName: 'Website roadmap request ID',
  name: 'website_roadmap_request_id',
  required: true,
  type: 'number'
}
export const roadmapRequestTitle: analytics.Property<string> = {
  displayName: 'Website roadmap request title',
  name: 'website_roadmap_request_title',
  required: true,
  type: 'string'
}

/**
 * Screens
 */

export const solutionScreen = new analytics.Event({
  displayName: 'Website solution screen',
  name: 'website_solution_screen',
  tags: [analytics.Tags.Screen]
})
export const featuresScreen = new analytics.Event({
  displayName: 'Website features screen',
  name: 'website_features_screen',
  tags: [analytics.Tags.Screen]
})
export const pricingScreen = new analytics.Event({
  displayName: 'Website pricing screen',
  name: 'website_pricing_screen',
  tags: [analytics.Tags.Screen]
})
export const learnScreen = new analytics.Event({
  displayName: 'Website learn screen',
  name: 'website_learn_screen',
  tags: [analytics.Tags.Screen]
})
export const roadmapScreen = new analytics.Event({
  displayName: 'Website roadmap screen',
  name: 'website_roadmap_screen',
  tags: [analytics.Tags.Screen]
})
export const c4ModelScreen = new analytics.Event({
  displayName: 'Website C4 model screen',
  name: 'website_c4_model_screen',
  tags: [analytics.Tags.Screen]
})
export const securityScreen = new analytics.Event({
  displayName: 'Website security screen',
  name: 'website_security_screen',
  tags: [analytics.Tags.Screen]
})
export const contactScreen = new analytics.Event({
  displayName: 'Website contact screen',
  name: 'website_contact_screen',
  tags: [analytics.Tags.Screen]
})
export const changelogScreen = new analytics.Event({
  displayName: 'Website changelog screen',
  name: 'website_changelog_screen',
  tags: [analytics.Tags.Screen]
})
export const ssoRegistrationSamlScreen = new analytics.Event({
  displayName: 'Website SSO registration SAML screen',
  name: 'website_sso_registration_saml_screen',
  tags: [analytics.Tags.Screen]
})
export const pageNotFoundScreen = new analytics.Event({
  displayName: 'Website page not found screen',
  name: 'website_page_not_found_screen',
  tags: [analytics.Tags.Screen]
})
export const blogScreen = new analytics.Event({
  displayName: 'Website blog screen',
  name: 'website_blog_screen',
  tags: [analytics.Tags.Screen]
})
export const careersScreen = new analytics.Event({
  displayName: 'Website careers screen',
  name: 'website_careers_screen',
  tags: [analytics.Tags.Screen]
})
export const caseStudyScreen = new analytics.Event({
  displayName: 'Website case study screen',
  name: 'website_case_study_screen',
  tags: [analytics.Tags.Screen]
})

/**
 * Dialogs
 */

export const isolationCalculatorDialog = new analytics.Event({
  displayName: 'Website isolation calculator dialog',
  name: 'website_isolation_calculator_dialog',
  tags: [analytics.Tags.Dialog]
})

/**
 * Links
 */

export const getStartedLink = new analytics.Event({
  displayName: 'Website get started link',
  name: 'website_get_started_link',
  tags: [analytics.Tags.Link]
}, {
  getStartedPosition
})
export const upgradeLink = new analytics.Event({
  displayName: 'Website upgrade link',
  name: 'website_upgrade_link',
  tags: [analytics.Tags.Link]
}, {
  upgradePlan
})
export const signUpLink = new analytics.Event({
  displayName: 'Website sign up link',
  name: 'website_sign_up_link',
  tags: [analytics.Tags.Link]
})
export const interactiveExampleLink = new analytics.Event({
  displayName: 'Website interactive example link',
  name: 'website_interactive_example_link',
  tags: [analytics.Tags.Link]
}, {
  interactiveExamplePosition
})
export const seeInActionLink = new analytics.Event({
  displayName: 'Website see in action link',
  name: 'website_see_in_action_link',
  tags: [analytics.Tags.Link]
}, {
  seeInActionType
})
export const videoLink = new analytics.Event({
  displayName: 'Website video link',
  name: 'website_video_link',
  tags: [analytics.Tags.Link]
}, {
  videoType
})
export const logInLink = new analytics.Event({
  displayName: 'Website log in link',
  name: 'website_log_in_link',
  tags: [analytics.Tags.Link]
})
export const socialMediumLink = new analytics.Event({
  displayName: 'Website social Medium link',
  name: 'website_social_medium_link',
  tags: [analytics.Tags.Link]
})
export const socialDevToLink = new analytics.Event({
  displayName: 'Website social Dev.to link',
  name: 'website_social_dev_to_link',
  tags: [analytics.Tags.Link]
})
export const socialFacebookLink = new analytics.Event({
  displayName: 'Website social Facebook link',
  name: 'website_social_facebook_link',
  tags: [analytics.Tags.Link]
})
export const socialTwitterLink = new analytics.Event({
  displayName: 'Website social Twitter link',
  name: 'website_social_twitter_link',
  tags: [analytics.Tags.Link]
})
export const socialLinkedinLink = new analytics.Event({
  displayName: 'Website social LinkedIn link',
  name: 'website_social_linkedin_link',
  tags: [analytics.Tags.Link]
})
export const c4ModelLink = new analytics.Event({
  displayName: 'Website C4 model link',
  name: 'website_c4_model_link',
  tags: [analytics.Tags.Link]
})
export const docsLink = new analytics.Event({
  displayName: 'Website docs link',
  name: 'website_docs_link',
  tags: [analytics.Tags.Link]
}, {
  docsPosition,
  link
})
export const blogLink = new analytics.Event({
  displayName: 'Website blog link',
  name: 'website_blog_link',
  tags: [analytics.Tags.Link]
}, {
  blogPosition
})
export const statusLink = new analytics.Event({
  displayName: 'Website status link',
  name: 'website_status_link',
  tags: [analytics.Tags.Link]
})
export const feedbackLink = new analytics.Event({
  displayName: 'Website feedback link',
  name: 'website_feedback_link',
  tags: [analytics.Tags.Link]
})
export const bookDemoLink = new analytics.Event({
  displayName: 'Website book a demo link',
  name: 'website_book_demo_link',
  tags: [analytics.Tags.Link]
}, {
  bookDemoPlan,
  bookDemoPosition
})
export const trustReportLink = new analytics.Event({
  displayName: 'Website trust report link',
  name: 'website_trust_report_link',
  tags: [analytics.Tags.Link]
})
export const pilotPurchaseLink = new analytics.Event({
  displayName: 'Website pilot purchase link',
  name: 'website_pilot_purchase_link',
  tags: [analytics.Tags.Link]
})

/**
 * Events
 */

export const ssoRegistrationSaml = new analytics.Event({
  displayName: 'Website SSO registration SAML',
  name: 'website_sso_registration_saml'
})
export const contactForm = new analytics.Event({
  displayName: 'Website contact form',
  name: 'website_contact_form'
})
export const contact = new analytics.Event({
  displayName: 'Website contact',
  name: 'website_contact'
}, {
  messageBody,
  messageFeeling,
  messageLength
})
export const dataRequest = new analytics.Event({
  displayName: 'Website data request',
  name: 'website_data_request'
}, {
  dataRequestType
})
export const newsSubscribe = new analytics.Event({
  displayName: 'Website news subscribe',
  name: 'website_news_subscribe'
})
export const solutionVideoPlay = new analytics.Event({
  displayName: 'Website solution video play',
  name: 'website_solution_video_play'
})
export const solutionVideoEnd = new analytics.Event({
  displayName: 'Website solution video end',
  name: 'website_solution_video_end'
})
export const learnVideoPlay = new analytics.Event({
  displayName: 'Website learn video play',
  name: 'website_learn_video_play'
})
export const learnVideoEnd = new analytics.Event({
  displayName: 'Website learn video end',
  name: 'website_learn_video_end'
})
export const featuresVideoPlay = new analytics.Event({
  displayName: 'Website features video play',
  name: 'website_features_video_play'
})
export const featuresVideoEnd = new analytics.Event({
  displayName: 'Website features video end',
  name: 'website_features_video_end'
})
export const c4modelVideoPlay = new analytics.Event({
  displayName: 'Website C4 model video play',
  name: 'website_c4_model_video_play'
})
export const c4modelVideoEnd = new analytics.Event({
  displayName: 'Website C4 model video end',
  name: 'website_c4_model_video_end'
})
export const roadmapRequest = new analytics.Event({
  displayName: 'Website roadmap request',
  name: 'website_roadmap_request'
}, {
  roadmapRequestId,
  roadmapRequestTitle
})

/**
 * Hidden
 */
export const pilotContactLink = new analytics.Event({
  displayName: 'Website pilot contact link',
  hidden: true,
  name: 'website_pilot_contact_link',
  tags: [analytics.Tags.Link]
})

export const solutionStage: analytics.Property<'design' | 'maintain' | 'explain'> = {
  displayName: 'Website solution stage',
  hidden: true,
  name: 'website_solution_stage',
  required: true,
  type: 'string'
}
export const solutionVideo: analytics.Property<string> = {
  displayName: 'Website solution video',
  hidden: true,
  name: 'website_solution_video',
  required: true,
  type: 'string'
}
export const solutionImage: analytics.Property<'collaboration' | 'slack'> = {
  displayName: 'Website solution image',
  hidden: true,
  name: 'website_solution_image',
  required: true,
  type: 'string'
}
export const sequenceDiagramsScreenHidden = new analytics.Event({
  displayName: 'Website sequence diagrams screen',
  hidden: true,
  name: 'website_sequence_diagrams_screen',
  tags: [analytics.Tags.Screen]
})
export const solutionVideoOpenHidden = new analytics.Event({
  displayName: 'Website solution video open',
  hidden: true,
  name: 'website_solution_video_open'
}, {
  solutionVideo
})
export const solutionVideoCloseHidden = new analytics.Event({
  displayName: 'Website solution video close',
  hidden: true,
  name: 'website_solution_video_close'
}, {
  solutionVideo
})
export const solutionImageOpenHidden = new analytics.Event({
  displayName: 'Website solution image open',
  hidden: true,
  name: 'website_solution_image_open'
}, {
  solutionImage
})
export const solutionImageCloseHidden = new analytics.Event({
  displayName: 'Website solution image close',
  hidden: true,
  name: 'website_solution_image_close'
}, {
  solutionImage
})
export const loginIcePanelLinkHidden = new analytics.Event({
  displayName: 'Website login IcePanel link',
  hidden: true,
  name: 'website_login_icepanel_link',
  tags: [analytics.Tags.Link]
})
export const supportUploadHidden = new analytics.Event({
  displayName: 'Website support upload',
  hidden: true,
  name: 'website_support_upload'
})
export const getStartedCallToActionHidden = new analytics.Event({
  displayName: 'Website get started call to action',
  hidden: true,
  name: 'website_get_started_call_to_action'
})
export const getDemoCallToActionHidden = new analytics.Event({
  displayName: 'Website get demo call to action',
  hidden: true,
  name: 'website_get_demo_call_to_action'
})
export const contactForIntroLinkHidden = new analytics.Event({
  displayName: 'Website contact for intro link',
  hidden: true,
  name: 'website_contact_for_intro_link',
  tags: [analytics.Tags.Link]
})
export const exploreIcePanelPositionHidden: analytics.Property<'top' | 'bottom' | undefined> = {
  displayName: 'Website explore IcePanel position',
  examples: ['top', 'bottom'],
  hidden: true,
  name: 'website_explore_icepanel_position',
  required: false,
  type: 'string'
}
export const exploreIcePanelLinkHidden = new analytics.Event({
  displayName: 'Website explore IcePanel link',
  hidden: true,
  name: 'website_explore_icepanel_link',
  tags: [analytics.Tags.Link]
}, {
  exploreIcePanelPositionHidden
})
export const demoVideoPlayHidden = new analytics.Event({
  displayName: 'Website demo video play',
  hidden: true,
  name: 'website_demo_video_play'
})
export const demoVideoEndHidden = new analytics.Event({
  displayName: 'Website demo video end',
  hidden: true,
  name: 'website_demo_video_end'
})
export const whyIcePanelVideoPlayHidden = new analytics.Event({
  displayName: 'Website why IcePanel video play',
  hidden: true,
  name: 'website_why_icepanel_video_play'
})
export const whyIcePanelVideoEndHidden = new analytics.Event({
  displayName: 'Website why IcePanel video end',
  hidden: true,
  name: 'website_why_icepanel_video_end'
})
export const dataRequestScreenHidden = new analytics.Event({
  displayName: 'Website data request screen',
  hidden: true,
  name: 'website_data_request_screen',
  tags: [analytics.Tags.Screen]
})
export const enterpriseContactLinkHidden = new analytics.Event({
  displayName: 'Website enterprise contact link',
  hidden: true,
  name: 'website_enterprise_contact_link',
  tags: [analytics.Tags.Link]
})
export const enterpriseCalculatorDialogHidden = new analytics.Event({
  displayName: 'Website enterprise calculator dialog',
  hidden: true,
  name: 'website_enterprise_calculator_dialog',
  tags: [analytics.Tags.Dialog]
})
export const dataProcessingAddendumScreenHidden = new analytics.Event({
  displayName: 'Website data processing addendum screen',
  hidden: true,
  name: 'website_data_processing_addendum_screen',
  tags: [analytics.Tags.Screen]
})
export const termsOfServiceScreenHidden = new analytics.Event({
  displayName: 'Website terms of service screen',
  hidden: true,
  name: 'website_terms_of_service_screen',
  tags: [analytics.Tags.Screen]
})
export const privacyPolicyScreenHidden = new analytics.Event({
  displayName: 'Website privacy policy screen',
  hidden: true,
  name: 'website_privacy_policy_screen',
  tags: [analytics.Tags.Screen]
})
export const trialLinkHidden = new analytics.Event({
  displayName: 'Website trial link',
  hidden: true,
  name: 'website_trial_link',
  tags: [analytics.Tags.Link]
})

export const trialPlanHidden: analytics.Property<OrganizationPlan> = {
  displayName: 'Website trial plan',
  hidden: true,
  name: 'website_trial_plan',
  required: true,
  type: 'string'
}
