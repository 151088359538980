import { Module, Mutation, VuexModule } from 'vuex-module-decorators'

import * as mixpanel from '../plugins/mixpanel'

export interface ITrackingModule {
  enabled: boolean | null
}

const name = 'tracking'

const tracking = localStorage.getItem('tracking')

@Module({
  name,
  namespaced: true
})
export class TrackingModule extends VuexModule {
  static namespace = name

  enabled: boolean | null = tracking ? tracking === 'true' : null

  @Mutation
  setTracking (enabled: boolean) {
    this.enabled = enabled

    localStorage.setItem('tracking', `${enabled}`)

    if (enabled) {
      mixpanel.optIn()
    } else {
      mixpanel.optOut()
    }
  }
}
