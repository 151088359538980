// eslint-disable-next-line
import 'reflect-metadata'
// eslint-disable-next-line
import 'vuetify'
// eslint-disable-next-line
import store from './store'

import '@/plugins/mixpanel'

import { OpenAPI } from '@icepanel/platform-api-client'
import Vue from 'vue'
import VueYoutube from 'vue-youtube'

import App from '@/app.vue'
import * as filters from '@/helpers/filters'
import router from '@/plugins/router'
import vuetify from '@/plugins/vuetify'
import Container from '@/components/container.vue'

OpenAPI.BASE = process.env.VUE_APP_ICE_API || (window.Cypress && window.Cypress.env('ICE_API')) || 'http://localhost:4000/v1'

Vue.config.productionTip = false

Vue.filter('date', filters.date)
Vue.filter('capitalize', filters.capitalize)

Vue.use(VueYoutube)
Vue.component('Container', Container)

const vue = new Vue({
  render: h => h(App),
  router,
  store,
  vuetify
})

vue.$mount('#app')

export default vue
