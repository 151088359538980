export default [{
  name: 'UTM IDE Plugin Discord (Experiment 1)',
  path: '/l/80fb2090',
  redirect: {
    name: 'solution',
    query: {
      utm_campaign: 'IDE_Plugin',
      utm_medium: 'chatroom_message',
      utm_source: 'discord'
    }
  }
}, {
  name: 'UTM VSCode Marketplace Download Discord (Experiment 2)',
  path: '/l/fbfa035a',
  redirect: {
    name: 'solution',
    query: {
      utm_campaign: 'VSCode_Marketplace_Download_E2',
      utm_medium: 'chatroom_message',
      utm_source: 'discord'
    }
  }
}]
