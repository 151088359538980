
import Vue from 'vue'
import Component from 'vue-class-component'

import LargeNavbar from './components/large-navbar.vue'
import LoginButton from './components/login-button.vue'
import SignUpButton from './components/sign-up-button.vue'
import SmallNavbar from './components/small-navbar.vue'
import { navigationItems } from './helpers/navigation-items'

@Component({
  components: {
    LargeNavbar,
    LoginButton,
    SignUpButton,
    SmallNavbar
  },
  name: 'Navbar'
})
export default class extends Vue {
  navigationItems = navigationItems

  get showHome () {
    return this.$route.name === 'terms-of-service' || this.$route.name === 'privacy-policy'
  }

  get showTabs () {
    return (this.$vuetify.breakpoint.mdAndDown || this.$route.name !== 'pricing') && this.$route.name !== 'terms-of-service' && this.$route.name !== 'privacy-policy'
  }
}
