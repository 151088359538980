
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'

import { NavigationItem, navigationItems } from '../helpers/navigation-items'
import LoginButton from './login-button.vue'
import NavbarDropdown from './navbar-dropdown.vue'
import SignUpButton from './sign-up-button.vue'

@Component({
  components: {
    LoginButton,
    NavbarDropdown,
    SignUpButton
  },
  name: 'NavBarLarge'
})
export default class extends Vue {
  @Prop({ type: Boolean }) showHome!: boolean
  @Prop({ type: Boolean }) showTabs!: boolean

  @Ref() lowerAppBar?: any

  navigationItems = navigationItems

  goTo (tab: NavigationItem) {
    if (tab.link) {
      this.$router.push({ name: tab.link.name })
    } else if (tab.href) {
      window.open(tab.href, tab.target || '_self')
    }
  }

  refreshAppBar () {
    if (this.lowerAppBar) {
      this.lowerAppBar.currentScroll = 0
      this.lowerAppBar.thresholdMet()
    }
  }

  @Watch('$route.name')
  routeNameChanged () {
    this.refreshAppBar()
  }
}
