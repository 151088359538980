
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import CustomerQuotes from '@/components/customer-quotes.vue'
import Mountain from '@/components/mountain.vue'
import Video from '@/components/video.vue'
import { getDemoLinks } from '@/helpers/demoLinks'
import { TrackingModule } from '@/store'

import * as analytics from '../../helpers/analytics'

  @Component({
    components: {
      CustomerQuotes,
      Mountain,
      Video
    },
    metaInfo: {
      link: [
        {
          href: 'https://icepanel.io/case-study/seer-interactive', rel: 'canonical'
        }
      ],
      meta: [
        { content: 'case study,seer interactive,icepanel', name: 'keywords', vmid: 'keywords' },
        { content: 'https://icepanel.io/case-study/seer-interactive', name: 'og:url', vmid: 'og:url' },
        { content: 'Case Study: Seer Interactive | IcePanel', name: 'og:title', vmid: 'og:title' },
        { content: 'Case Study: Seer Interactive | IcePanel', name: 'twitter:title', vmid: 'twitter:title' },
        { content: 'Read the Seer Interactive success story on IcePanel', name: 'description', vmid: 'description' },
        { content: 'Read the Seer Interactive success story on IcePanel', name: 'og:description', vmid: 'og:description' },
        { content: 'Read the Seer Interactive success story on IcePanel', name: 'twitter:description', vmid: 'twitter:description' }
      ],
      title: 'Case Study: Seer Interactive | IcePanel'
    },
    name: 'SeerInteractiveCaseStudy'
  })
export default class extends Vue {
    trackingModule = getModule(TrackingModule, this.$store)

    get responsiveMarginStyles () {
      if (this.$vuetify.breakpoint.lgAndUp) {
        return 'margin-top: -380px;'
      }
      if (this.$vuetify.breakpoint.mdOnly) {
        return 'margin-top: -240px;'
      }

      return 'margin-top: -120px;'
    }

    get appUrl () {
      return `https://app.icepanel.${process.env.VUE_APP_ICE_ENV === 'production' ? 'io' : 'dev'}`
    }

    get demoLink () {
      return getDemoLinks()
    }

    get tracking () {
      return this.trackingModule.enabled
    }

    @Watch('tracking')
    onTrackingChanged (tracking: boolean, prevTracking: boolean) {
      if (tracking && !prevTracking) {
        analytics.caseStudyScreen.track(this)
      }
    }

    mounted () {
      analytics.caseStudyScreen.track(this)
    }

    getStartedLink (getStartedPosition: 'top' | 'bottom') {
      analytics.getStartedLink.track(this, {
        getStartedPosition
      })
    }

    bookDemoLink (bookDemoPosition: 'top' | 'bottom') {
      analytics.bookDemoLink.track(this, {
        bookDemoPosition
      })
    }
}
