
import { OrganizationBillingCurrency } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'

import * as analytics from '../helpers/analytics'
import { CURRENCIES } from '../helpers/currencies'

const SEAT_PRICES: Record<number, Record<OrganizationBillingCurrency, number>> = {
  100: {
    cad: 30.5,
    eur: 20.5,
    gbp: 18,
    usd: 22.5
  },
  200: {
    cad: 27,
    eur: 18,
    gbp: 16,
    usd: 20
  },
  300: {
    cad: 23.5,
    eur: 15.5,
    gbp: 14,
    usd: 17.5
  },
  400: {
    cad: 20,
    eur: 13,
    gbp: 12,
    usd: 15
  },
  50: {
    cad: 34,
    eur: 23,
    gbp: 20,
    usd: 25
  }
}

const ISOLATED_ENVIRONMENT_PRICES: Record<OrganizationBillingCurrency, number> = {
  cad: 113500,
  eur: 77600,
  gbp: 67000,
  usd: 85000
}

@Component({
  name: 'IsolationCalculatorDialog'
})
export default class extends Vue {
  @Ref() readonly dialogRef!: any

  @Prop({ default: () => {} }) readonly dialogProps!: any

  billingCurrency: OrganizationBillingCurrency = localStorage.getItem('billingCurrency') as OrganizationBillingCurrency | null || 'usd'

  seatCount = 50
  seatCountModel = 50
  seatCountMin = 50
  seatCountMax = 1000

  selectBooleanItems = [{ title: 'Yes', value: true }, { title: 'No', value: false }]

  currencies = CURRENCIES

  get seatPrices () {
    return [
      {
        active: this.seatCount >= 50 && this.seatCount <= 99,
        id: 50,
        price: SEAT_PRICES[50][this.billingCurrency],
        priceText: SEAT_PRICES[50][this.billingCurrency].toLocaleString(this.localeString, {
          currency: this.billingCurrency,
          maximumFractionDigits: 1,
          minimumFractionDigits: 0,
          style: 'currency'
        }),
        rangeText: '50-99'
      },
      {
        active: this.seatCount >= 100 && this.seatCount <= 199,
        id: 100,
        price: SEAT_PRICES[100][this.billingCurrency],
        priceText: SEAT_PRICES[100][this.billingCurrency].toLocaleString(this.localeString, {
          currency: this.billingCurrency,
          maximumFractionDigits: 1,
          minimumFractionDigits: 0,
          style: 'currency'
        }),
        rangeText: '100-199'
      },
      {
        active: this.seatCount >= 200 && this.seatCount <= 299,
        id: 20,
        price: SEAT_PRICES[200][this.billingCurrency],
        priceText: SEAT_PRICES[200][this.billingCurrency].toLocaleString(this.localeString, {
          currency: this.billingCurrency,
          maximumFractionDigits: 1,
          minimumFractionDigits: 0,
          style: 'currency'
        }),
        rangeText: '200-299'
      },
      {
        active: this.seatCount >= 300 && this.seatCount <= 399,
        id: 300,
        price: SEAT_PRICES[300][this.billingCurrency],
        priceText: SEAT_PRICES[300][this.billingCurrency].toLocaleString(this.localeString, {
          currency: this.billingCurrency,
          maximumFractionDigits: 1,
          minimumFractionDigits: 0,
          style: 'currency'
        }),
        rangeText: '300-399'
      },
      {
        active: this.seatCount >= 400,
        id: 400,
        price: SEAT_PRICES[400][this.billingCurrency],
        priceText: SEAT_PRICES[400][this.billingCurrency].toLocaleString(this.localeString, {
          currency: this.billingCurrency,
          maximumFractionDigits: 1,
          minimumFractionDigits: 0,
          style: 'currency'
        }),
        rangeText: '400+'
      }
    ]
  }

  get currentCurrency () {
    return CURRENCIES.find(o => o.id === this.billingCurrency)!
  }

  get localeString () {
    switch (this.billingCurrency) {
      case 'cad':
        return 'en-CA'
      case 'eur':
        return 'de-DE'
      case 'gbp':
        return 'en-GB'
      case 'usd':
        return 'en-US'
      default:
        return 'en-US'
    }
  }

  get getSeatPrice () {
    const seatPriceThreshold = this.seatPrices.find(o => o.active)
    const seatPricePerSeat = seatPriceThreshold?.price || 0
    const seatPrice = seatPricePerSeat * this.seatCount * 12
    return seatPrice.toLocaleString(this.localeString, {
      currency: this.billingCurrency,
      maximumFractionDigits: 1,
      minimumFractionDigits: 0,
      style: 'currency'
    })
  }

  get getIsolatedEnvironmentPrice () {
    return ISOLATED_ENVIRONMENT_PRICES[this.billingCurrency].toLocaleString(this.localeString, {
      currency: this.billingCurrency,
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
      style: 'currency'
    })
  }

  get getTotalPrice () {
    const seatPriceThreshold = this.seatPrices.find(o => o.active)
    const seatPricePerSeat = seatPriceThreshold?.price || 0
    const seatPrice = seatPricePerSeat * this.seatCount * 12
    const isolatedEnvironmentPrice = ISOLATED_ENVIRONMENT_PRICES[this.billingCurrency]
    return (seatPrice + isolatedEnvironmentPrice).toLocaleString(this.localeString, {
      currency: this.billingCurrency,
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
      style: 'currency'
    })
  }

  @Watch('billingCurrency')
  onBillingCurrencyChanged (billingCurrency: OrganizationBillingCurrency) {
    this.$emit('billing_currency', billingCurrency)
    localStorage.setItem('billingCurrency', billingCurrency)
  }

  mounted () {
    if (this.$route.query.isolation_calculator_dialog) {
      this.open()
    }
  }

  validateSeatCount (updateModel: boolean) {
    this.seatCount = Math.max(this.seatCountMin, Math.min(this.seatCountMax, this.seatCountModel))

    if (updateModel) {
      this.seatCountModel = this.seatCount
    }
  }

  input (value: boolean) {
    this.$emit('input', value)

    if (value) {
      this.$router.replace({
        query: {
          isolation_calculator_dialog: '1'
        }
      })

      analytics.isolationCalculatorDialog.track(this)
    } else {
      this.$router.replace({
        query: {
          isolation_calculator_dialog: undefined
        }
      })
    }
  }

  open () {
    this.dialogRef.isActive = true
  }

  close () {
    this.dialogRef.isActive = false
  }
}
