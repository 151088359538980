
import { SupportMessageType } from '@icepanel/platform-api-client'
import * as mixpanel from 'mixpanel-browser'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Aurora from '@/components/aurora.vue'
import { SupportModule, TrackingModule } from '@/store'

import * as analytics from '../helpers/analytics'

const defaultModel = {
  email: '',
  feeling: '',
  message: '',
  name: '',
  subject: '',
  type: '' as Extract<SupportMessageType, 'sales' | 'support' | 'general'>
}

@Component({
  components: {
    Aurora
  },
  metaInfo: {
    meta: [
      { content: 'contact,message,chat,suggestion,support,bug,social', name: 'keywords', vmid: 'keywords' },
      { content: 'https://icepanel.io/contact', name: 'og:url', vmid: 'og:url' },
      { content: 'IcePanel | Contact | Explain complex software systems', name: 'og:title', vmid: 'og:title' },
      { content: 'IcePanel | Contact | Explain complex software systems', name: 'twitter:title', vmid: 'twitter:title' },
      { content: 'Got a question or just want to chat? Feel free to get in touch, we’d love to hear from you!', name: 'description', vmid: 'description' },
      { content: 'Got a question or just want to chat? Feel free to get in touch, we’d love to hear from you!', name: 'og:description', vmid: 'og:description' },
      { content: 'Got a question or just want to chat? Feel free to get in touch, we’d love to hear from you!', name: 'twitter:description', vmid: 'twitter:description' }
    ],
    title: 'IcePanel | Contact | Explain complex software systems'
  },
  name: 'Contact'
})
export default class extends Vue {
  supportModule = getModule(SupportModule, this.$store)
  trackingModule = getModule(TrackingModule, this.$store)

  @Ref() readonly form!: any

  model = {
    ...defaultModel,
    email: typeof this.$route.query.email === 'string' ? this.$route.query.email : '',
    message: this.supportModule.message || (typeof this.$route.query.message === 'string' ? this.$route.query.message : ''),
    name: typeof this.$route.query.name === 'string' ? this.$route.query.name : '',
    subject: typeof this.$route.query.subject === 'string' ? this.$route.query.subject : '',
    type: (typeof this.$route.query.type === 'string' ? this.$route.query.type : '') as Extract<SupportMessageType, 'sales' | 'support' | 'general'>
  }

  isSubmitting = false
  submitError: string | null = null
  submitSuccess = false

  get tracking () {
    return this.trackingModule.enabled
  }

  get types (): { text: string, value: SupportMessageType }[] {
    return [
      {
        text: 'Sales',
        value: 'sales'
      },
      {
        text: 'Support',
        value: 'support'
      },
      {
        text: 'Other',
        value: 'general'
      }
    ]
  }

  get feelings (): { text: string, icon: string, value: string }[] {
    return [
      {
        icon: '🤩',
        text: 'Excited',
        value: 'Excited 🤩'
      },
      {
        icon: '😀',
        text: 'Happy',
        value: 'Happy 😀'
      },
      {
        icon: '😐',
        text: 'Neutral',
        value: 'Neutral 😐'
      },
      {
        icon: '😢',
        text: 'Sad',
        value: 'Sad 😢'
      },
      {
        icon: '😡',
        text: 'Angry',
        value: 'Angry 😡'
      },
      {
        icon: '😎',
        text: 'Cool',
        value: 'Cool 😎'
      }
    ]
  }

  @Watch('tracking')
  onTrackingChanged (tracking: boolean, prevTracking: boolean) {
    if (tracking && !prevTracking) {
      analytics.contactScreen.track(this)
    }
  }

  mounted () {
    analytics.contactScreen.track(this)

    this.supportModule.setMessage('')
  }

  bookDemoLink () {
    analytics.bookDemoLink.track(this, {})
  }

  socialFacebookLink () {
    window.open('https://www.facebook.com/icepanel/', '_blank')?.focus()
    analytics.socialFacebookLink.track(this)
  }

  socialTwitterLink () {
    window.open('https://twitter.com/ice_panel/', '_blank')?.focus()
    analytics.socialTwitterLink.track(this)
  }

  socialLinkedinLink () {
    window.open('https://www.linkedin.com/company/26254332/', '_blank')?.focus()
    analytics.socialLinkedinLink.track(this)
  }

  contactFormStarted = false

  contactFormStart () {
    if (!this.contactFormStarted) {
      this.contactFormStarted = true
      analytics.contactForm.track(this)
      analytics.contact.time()
    }
  }

  async submit () {
    if (this.submitSuccess) {
      return
    }

    if (!this.form.validate()) {
      return
    }

    this.submitError = null
    this.submitSuccess = false
    this.isSubmitting = true

    try {
      const distinctId = mixpanel.get_distinct_id() as string | undefined

      await this.supportModule.supportMessageCreate({
        body: {
          feeling: this.model.feeling,
          message: this.model.message,
          subject: this.model.subject,
          type: this.model.type
        },
        email: this.model.email,
        name: this.model.name,
        url: window.location.href,
        userId: distinctId?.length === 20 ? distinctId : undefined
      })

      analytics.contact.track(this, {
        messageBody: this.model.message,
        messageFeeling: this.model.feeling,
        messageLength: this.model.message.length
      })

      this.submitSuccess = true

      this.form.resetValidation()
      this.model = { ...defaultModel }
    } catch (err: any) {
      this.submitError = err.body ? err.body.message : err.message
      throw err
    } finally {
      this.isSubmitting = false
    }
  }
}
