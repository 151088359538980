
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Aurora from '@/components/aurora.vue'
import * as analytics from '@/helpers/analytics'
import changelog from '@/helpers/changelog'
import { NewsModule, TrackingModule } from '@/store'

const defaultModel = {
  email: ''
}

@Component({
  components: {
    Aurora
  },
  metaInfo: {
    meta: [
      { content: 'public,changelog,release,notes,feature,fix,change,new', name: 'keywords', vmid: 'keywords' },
      { content: 'https://icepanel.io/changelog', name: 'og:url', vmid: 'og:url' },
      { content: 'IcePanel | Changelog | Explain complex software systems', name: 'og:title', vmid: 'og:title' },
      { content: 'IcePanel | Changelog | Explain complex software systems', name: 'twitter:title', vmid: 'twitter:title' },
      { content: 'List of everything we have done so far... New updates are pushed every 2 weeks!', name: 'description', vmid: 'description' },
      { content: 'List of everything we have done so far... New updates are pushed every 2 weeks!', name: 'og:description', vmid: 'og:description' },
      { content: 'List of everything we have done so far... New updates are pushed every 2 weeks!', name: 'twitter:description', vmid: 'twitter:description' }
    ],
    title: 'IcePanel | Changelog | Explain complex software systems'
  },
  name: 'Changelog'
})
export default class extends Vue {
  newsModule = getModule(NewsModule, this.$store)
  trackingModule = getModule(TrackingModule, this.$store)

  @Ref() readonly form!: any

  model = { ...defaultModel }

  isSubmitting = false
  submitError: string | null = null
  submitSuccess = false

  get sections () {
    return [
      {
        icon: '$custom-regular-plus-circle',
        iconColor: 'accent',
        id: 'added',
        name: 'Added'
      },
      {
        icon: '$fas-bug',
        iconColor: 'error',
        id: 'fixed',
        name: 'Squashed bugs'
      },
      {
        icon: '$custom-regular-pencil',
        iconColor: 'info',
        id: 'changed',
        name: 'Changed'
      }
    ]
  }

  get versions () {
    return changelog
  }

  get tracking () {
    return this.trackingModule.enabled
  }

  @Watch('tracking')
  onTrackingChanged (tracking: boolean, prevTracking: boolean) {
    if (tracking && !prevTracking) {
      analytics.changelogScreen.track(this)
    }
  }

  mounted () {
    analytics.changelogScreen.track(this)
  }

  async submit () {
    if (this.submitSuccess) {
      return
    }

    if (!this.form.validate()) {
      return
    }

    this.submitError = null
    this.submitSuccess = false
    this.isSubmitting = true

    try {
      await this.newsModule.newsSubscribe({
        email: this.model.email
      })

      analytics.newsSubscribe.track(this)

      this.submitSuccess = true

      this.form.resetValidation()
      this.model = { ...defaultModel }
    } catch (err: any) {
      this.submitError = err.body ? err.body.message : err.message
      throw err
    } finally {
      this.isSubmitting = false
    }
  }
}
