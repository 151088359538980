import * as mixpanel from 'mixpanel-browser'

import { normalise } from './helpers'
import { Property, PropertyTypes, Type } from './types'

export class Event<P extends Record<string, Property<any>> = Record<string, never>> {
  public readonly type = 'event'
  public readonly description?: string
  public readonly displayName?: string
  public readonly hidden?: boolean
  public readonly name: string
  public readonly tags: string[]

  constructor (
    props: {
      description?: string
      displayName?: string
      hidden?: boolean
      name: string
      tags?: string[]
    },
    public readonly properties?: P extends Record<string, never> ? Record<string, Property<any>> : P
  ) {
    this.description = props.description
    this.displayName = props.displayName
    this.hidden = props.hidden
    this.name = props.name
    this.tags = props.tags || []
  }

  track (context: { $route: { name?: string | null | undefined }, $options: { name?: string | undefined } }, ...properties: P extends Record<string, never> ? [] : [PropertyTypes<P>]) {
    const props: Record<string, Type> = {}
    for (const key in this.properties) {
      const prop = this.properties[key]
      if (properties[0]?.[key] !== undefined) {
        props[prop.name] = normalise(prop.type, properties[0][key])
      }
    }
    mixpanel.track(this.name, {
      component: context.$options.name,
      route: context.$route.name,
      ...props
    })
  }

  time () {
    mixpanel.time_event(this.name)
  }

  schema (tags: string[] = []) {
    return {
      entityType: this.type,
      name: this.name,
      schemaJson: {
        $schema: 'http://json-schema.org/draft-07/schema',
        additionalProperties: true,
        description: this.description || '',
        metadata: {
          'com.mixpanel': {
            displayName: this.displayName || '',
            hidden: this.hidden === undefined ? false : this.hidden,
            tags: [...this.tags, ...tags]
          }
        },
        properties: Object
          .values(this.properties || {})
          .reduce((p, c) => ({
            ...p,
            [c.name]: {
              description: c.description || '',
              examples: c.examples || [],
              format: c.type === 'date' ? 'date-time' : undefined,
              metadata: {
                'com.mixpanel': {
                  displayName: c.displayName || '',
                  hidden: c.hidden === undefined ? false : c.hidden
                }
              },
              type: c.type === 'date' ? 'string' : c.type
            }
          }), {}),
        required: Object.values(this.properties || {}).filter(o => o.required).map(o => o.name)
      }
    }
  }
}
