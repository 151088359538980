import discord from './discord'
import facebook from './facebook'
import linkedin from './linkedin'
import other from './other'
import reddit from './reddit'
import slack from './slack'
import twitter from './twitter'
import ycombinator from './ycombinator'

export default [
  ...discord,
  ...facebook,
  ...linkedin,
  ...other,
  ...slack,
  ...reddit,
  ...twitter,
  ...ycombinator
]
