export type Type = null | string | number | boolean | Date | any[]

export type Property<T extends Type | undefined> = {
  description?: string
  displayName?: string
  examples?: (T extends Date ? Date | string : T)[]
  hidden?: boolean
  name: string
  required: undefined extends T ? false : true
  type: T extends string ? 'string' : T extends number ? 'number' : T extends boolean ? 'boolean' : T extends Date ? 'date' : T extends any[] ? 'array' : never
}

export type PropertyTypes<P extends Record<string, Property<any>>> = {
  [K in keyof P as P[K]['required'] extends true ? K : never]: NonNullable<P[K]['examples']>[0]
} & {
  [K in keyof P as P[K]['required'] extends false ? K : never]?: NonNullable<P[K]['examples']>[0]
}

export const Tags = {
  Dialog: 'Dialog',
  Link: 'Link',
  Menu: 'Menu',
  Screen: 'Screen'
} as const
