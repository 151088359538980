
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { VueTyper } from 'vue-typer'
import { getModule } from 'vuex-module-decorators'

import Aurora from '@/components/aurora.vue'
import CustomerQuotes from '@/components/customer-quotes.vue'
import Iceberg from '@/components/iceberg.vue'
import Mountain from '@/components/mountain.vue'
import Demo from '@/components/sections/demo.vue'
import Examples from '@/components/sections/examples.vue'
import TrustedBy from '@/components/trusted-by.vue'
import Video from '@/components/video.vue'
import { getDemoLinks } from '@/helpers/demoLinks'
import { TrackingModule } from '@/store'

import * as analytics from '../helpers/analytics'

@Component({
  components: {
    Aurora,
    CustomerQuotes,
    Demo,
    Examples,
    Iceberg,
    Mountain,
    TrustedBy,
    Video,
    VueTyper
  },
  /*
    metaInfo: {
      All meta tags inherited from ./app.vue default metaInfo object.
    },
  */
  name: 'Solution'
})
export default class extends Vue {
  trackingModule = getModule(TrackingModule, this.$store)

  get demoLink () {
    return getDemoLinks()
  }

  get appUrl () {
    return `https://app.icepanel.${process.env.VUE_APP_ICE_ENV === 'production' ? 'io' : 'dev'}`
  }

  get tracking () {
    return this.trackingModule.enabled
  }

  get visualStorytellingHeadings () {
    return [
      'Architects',
      'Developers',
      'Product',
      'Business'
    ]
  }

  @Watch('tracking')
  onTrackingChanged (tracking: boolean, prevTracking: boolean) {
    if (tracking && !prevTracking) {
      analytics.solutionScreen.track(this)
    }
  }

  mounted () {
    analytics.solutionScreen.track(this)
  }

  getStartedLink (getStartedPosition: 'top' | 'bottom') {
    analytics.getStartedLink.track(this, {
      getStartedPosition
    })
  }

  interactiveExampleLink (interactiveExamplePosition: 'top' | 'bottom') {
    analytics.interactiveExampleLink.track(this, {
      interactiveExamplePosition
    })
  }

  seeInActionLink (seeInActionType: 'model' | 'tags' | 'flows' | 'reality-linking') {
    analytics.seeInActionLink.track(this, {
      seeInActionType
    })
  }

  videoLink (videoType: 'modelling' | 'c4-model') {
    analytics.videoLink.track(this, {
      videoType
    })
  }

  bookDemoLink (bookDemoPosition: 'top' | 'bottom') {
    analytics.bookDemoLink.track(this, {
      bookDemoPosition
    })
  }

  solutionVideoPlay () {
    analytics.solutionVideoPlay.track(this)
  }

  solutionVideoEnd () {
    analytics.solutionVideoEnd.track(this)
  }
}
