
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Aurora from '@/components/aurora.vue'
import * as analytics from '@/helpers/analytics'
import { SupportModule, TrackingModule } from '@/store'

@Component({
  components: {
    Aurora
  },
  metaInfo: {
    meta: [
      { content: 'security,soc2,compliance,secure,authentication,data,encryption,monitor,sso', name: 'keywords', vmid: 'keywords' },
      { content: 'https://icepanel.io/security', name: 'og:url', vmid: 'og:url' },
      { content: 'IcePanel | Security | Explain complex software systems', name: 'og:title', vmid: 'og:title' },
      { content: 'IcePanel | Security | Explain complex software systems', name: 'twitter:title', vmid: 'twitter:title' },
      { content: '', name: 'description', vmid: 'description' },
      { content: '', name: 'og:description', vmid: 'og:description' },
      { content: '', name: 'twitter:description', vmid: 'twitter:description' }
    ],
    title: 'IcePanel | Security | Explain complex software systems'
  },
  name: 'Security'
})
export default class extends Vue {
  supportModule = getModule(SupportModule, this.$store)
  trackingModule = getModule(TrackingModule, this.$store)

  get people () {
    return [
      'Random passwords, 2FA and SSO enforced',
      'Organizational policies reviewed annually',
      'Risk assessments completed annually',
      'Vendor security reviewed',
      'Change control procedures'
    ]
  }

  get application () {
    return [
      'TLS 1.2/1.3 encryption in transit & rest',
      'Passwords are hashed & salted',
      ['Single sign on (SAML 2.0)', 'Growth plan+'],
      'Penetration testing performed annually',
      'Automate vulnerability scanning'
    ]
  }

  get database () {
    return [
      'Data is encrypted server-side',
      '256-bit AES encryption',
      'Backups daily & regular restoration testing',
      'Access logs retained'
    ]
  }

  get isolatedEnvironment () {
    return [
      'Single-tenant environment',
      ['Configurable cloud location', 'GCP locations'],
      'Additional network configuration',
      'Customer managed encryption keys'
    ]
  }

  get tracking () {
    return this.trackingModule.enabled
  }

  @Watch('tracking')
  onTrackingChanged (tracking: boolean, prevTracking: boolean) {
    if (tracking && !prevTracking) {
      analytics.securityScreen.track(this)
    }
  }

  mounted () {
    analytics.securityScreen.track(this)
  }

  contact () {
    this.supportModule.setMessage('I have a question about security.')

    this.$router.push({
      name: 'contact'
    })
  }

  trustReportLink () {
    analytics.trustReportLink.track(this)
  }
}
