import Vue from 'vue'
import VueMeta from 'vue-meta'
import VueRouter from 'vue-router'

import BlogPosts from '../../posts/posts.json'
import UTMRoutes from '../helpers/utm'
import Blog from '../views/blog/index.vue'
import Post from '../views/blog/post.vue'
import C4Model from '../views/c4-model.vue'
import Careers from '../views/careers/index.vue'
import CaseStudyAlphabet from '../views/case-study/alphabet.vue'
import CaseStudyEnergyWeb from '../views/case-study/energy-web.vue'
import CaseStudySeerInteractive from '../views/case-study/seer-interactive.vue'
import Changelog from '../views/changelog.vue'
import Contact from '../views/contact.vue'
import Features from '../views/features.vue'
import Learn from '../views/learn.vue'
import PageNotFound from '../views/page-not-found.vue'
import Pricing from '../views/pricing.vue'
import Roadmap from '../views/roadmap.vue'
import Security from '../views/security.vue'
import Solution from '../views/solution.vue'
import SSORegistrationSAML from '../views/sso-registration/saml.vue'

Vue.use(VueRouter)
Vue.use(VueMeta)

const BlogRoutes = BlogPosts.map(post => ({
  component: Post,
  props: true,
  ...post
}))

// const CareersRoutes = CareerJobs.map(post => ({
//   component: Job,
//   props: true,
//   ...post
// }))

const routes = [
  {
    component: Solution,
    name: 'solution',
    path: '/'
  },
  {
    component: Blog,
    name: 'blog',
    path: '/blog'
  },
  ...BlogRoutes,
  {
    component: Careers,
    name: 'careers',
    path: '/careers'
  },
  // ...CareersRoutes,
  {
    component: CaseStudyAlphabet,
    name: 'case-study:alphabet',
    path: '/case-study/alphabet'
  },
  {
    component: CaseStudyEnergyWeb,
    name: 'case-study:energy-web',
    path: '/case-study/energy-web'
  },
  {
    component: CaseStudySeerInteractive,
    name: 'case-study:seer-interactive',
    path: '/case-study/seer-interactive'
  },
  {
    component: Features,
    name: 'features',
    path: '/features'
  },
  {
    component: Roadmap,
    name: 'roadmap',
    path: '/roadmap'
  },
  {
    component: C4Model,
    name: 'c4-model',
    path: '/c4-model'
  },
  {
    component: Learn,
    name: 'learn',
    path: '/learn'
  },
  {
    component: Pricing,
    name: 'pricing',
    path: '/pricing'
  },
  {
    component: Contact,
    name: 'contact',
    path: '/contact'
  },
  {
    component: Changelog,
    name: 'changelog',
    path: '/changelog'
  },
  {
    component: Security,
    name: 'security',
    path: '/security'
  },
  {
    component: SSORegistrationSAML,
    name: 'sso-registration-saml',
    path: '/sso-registration/saml'
  },
  {
    component: () => {
      document.documentElement.innerHTML = ''
      window.location.href = 'https://docs.google.com/document/d/1lDdXZqBkGL7bKhIl-pSwW6H_Y4CDHMR4/edit?usp=sharing&ouid=101483300304989467783&rtpof=true&sd=true'
    },
    path: '/terms-of-service'
  },
  {
    component: () => {
      document.documentElement.innerHTML = ''
      window.location.href = 'https://docs.google.com/document/d/1dsvVWhD7fVx4Rr7nj9sOWUwLRxVvN0fh/edit?usp=sharing&ouid=101483300304989467783&rtpof=true&sd=true'
    },
    path: '/privacy-policy'
  },
  {
    component: () => {
      document.documentElement.innerHTML = ''
      window.location.href = 'https://docs.google.com/document/d/1XYLHq_1gF0l1JicXiyLFh2MpgZffS9Xb/edit?usp=sharing&ouid=101483300304989467783&rtpof=true&sd=true'
    },
    path: '/data-processing-agreement'
  },
  {
    component: () => {
      document.documentElement.innerHTML = ''
      window.location.href = 'https://icepaneltechnologiesinc.gdprlocal.com/eu'
    },
    path: '/data-request'
  },
  ...UTMRoutes,
  {
    component: PageNotFound,
    name: 'page-not-found',
    path: '*'
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return { selector: to.hash }
    } else if (from.path === to.path) {
      return
    }

    return { x: 0, y: 0 }
  }
})

export default router
