import { Property, Type } from './types'

export const normalise = (type: Property<any>['type'], val: Type): string | number | boolean | string[] => {
  if (type === 'date' && val instanceof Date) {
    return val.toISOString()
  } else if (type === 'array' && !(val instanceof Array)) {
    return [`${val}`]
  } else if (type === 'array' && val instanceof Array) {
    return val.map(o => `${o}`)
  } else if (type === 'string' && val === null) {
    return 'null'
  } else if (type === 'string' && typeof val === 'string') {
    return val
  } else if (type === 'boolean' && typeof val === 'boolean') {
    return val
  } else if (type === 'number' && typeof val === 'number') {
    return val
  } else if (type === 'date' && typeof val === 'string') {
    return val
  } else {
    throw new Error(`Failed to normalise ${type} type`)
  }
}
