export default [{
  name: 'UTM IDE Plugin Twitter (Experiment 1)',
  path: '/l/fa84f543',
  redirect: {
    name: 'solution',
    query: {
      utm_campaign: 'IDE_Plugin',
      utm_medium: 'social_media_post',
      utm_source: 'twitter'
    }
  }
}, {
  name: 'UTM VSCode Marketplace Download Twitter (Experiment 2)',
  path: '/l/25134c5f',
  redirect: {
    name: 'solution',
    query: {
      utm_campaign: 'VSCode_Marketplace_Download_E2',
      utm_medium: 'social_media_post',
      utm_source: 'twitter'
    }
  }
}, {
  name: 'UTM Homepage Twitter (Experiment 3)',
  path: '/l/JVxhfbzca',
  redirect: {
    name: 'solution',
    query: {
      utm_campaign: 'VSCode_Homepage_E3',
      utm_medium: 'social_media_post',
      utm_source: 'twitter'
    }
  }
}, {
  name: 'UTM Homepage Twitter (Experiment 4)',
  path: '/l/k7AI7MtGd',
  redirect: {
    name: 'solution',
    query: {
      utm_campaign: 'VSCode_Homepage_E4',
      utm_medium: 'social_media_post',
      utm_source: 'twitter'
    }
  }
}, {
  name: 'UTM Desktop App Twitter (Experiment 5)',
  path: '/l/JzmClqmPl',
  redirect: {
    name: 'solution',
    query: {
      utm_campaign: 'DesktopApp_E5',
      utm_medium: 'social_media_post',
      utm_source: 'twitter'
    }
  }
}]
