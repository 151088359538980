
import Vue from 'vue'
import Component from 'vue-class-component'

@Component({
  name: 'TrustedBy'
})
export default class extends Vue {
  get customers (): { altText: string, color: any, grey: string, opacity: number, height: string }[] {
    return [
      {
        altText: 'Cisco logo',
        color: require('../assets/customers/color/cisco.png'),
        grey: require('../assets/customers/grey/cisco.png'),
        height: '48px',
        opacity: 0.5
      },
      {
        altText: 'Alphabet logo',
        color: require('../assets/customers/color/alphabet.png'),
        grey: require('../assets/customers/grey/alphabet.png'),
        height: '22px',
        opacity: 0.5
      },
      {
        altText: 'BMW logo',
        color: require('../assets/customers/color/bmw.png'),
        grey: require('../assets/customers/grey/bmw.png'),
        height: '54px',
        opacity: 0.5
      },
      {
        altText: 'Smartpay logo',
        color: require('../assets/customers/color/smartpay.png'),
        grey: require('../assets/customers/grey/smartpay.png'),
        height: '30px',
        opacity: 0.5
      },
      {
        altText: 'Puregym logo',
        color: require('../assets/customers/color/puregym.png'),
        grey: require('../assets/customers/grey/puregym.png'),
        height: '40px',
        opacity: 0.5
      },
      {
        altText: 'University of cambridge logo',
        color: require('../assets/customers/color/university-of-cambridge.png'),
        grey: require('../assets/customers/grey/university-of-cambridge.png'),
        height: '28px',
        opacity: 0.5
      },
      {
        altText: 'Innovmetric logo',
        color: require('../assets/customers/color/innovmetric.png'),
        grey: require('../assets/customers/grey/innovmetric.png'),
        height: '30px',
        opacity: 0.5
      },
      {
        altText: 'Gorgias logo',
        color: require('../assets/customers/color/gorgias.png'),
        grey: require('../assets/customers/grey/gorgias.png'),
        height: '30px',
        opacity: 0.5
      },
      {
        altText: 'Exfo logo',
        color: require('../assets/customers/color/exfo.png'),
        grey: require('../assets/customers/grey/exfo.png'),
        height: '30px',
        opacity: 0.5
      }
    ]
  }
}
