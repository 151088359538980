export default [{
  name: 'UTM IDE Plugin Reddit (Experiment 1)',
  path: '/l/73544be2',
  redirect: {
    name: 'solution',
    query: {
      utm_campaign: 'IDE_Plugin',
      utm_medium: 'social_media_post',
      utm_source: 'reddit'
    }
  }
}, {
  name: 'UTM VSCode Marketplace Download Reddit (Experiment 2)',
  path: '/l/f9cb5eb9',
  redirect: {
    name: 'solution',
    query: {
      utm_campaign: 'VSCode_Marketplace_Download_E2',
      utm_medium: 'social_media_post',
      utm_source: 'reddit'
    }
  }
}, {
  name: 'UTM VSCode Plugin Reddit (Experiment 2)',
  path: '/l/119c90a9',
  redirect: {
    name: 'solution',
    query: {
      utm_campaign: 'VSCode_Plugin_E2',
      utm_medium: 'social_media_post',
      utm_source: 'reddit'
    }
  }
}, {
  name: 'UTM Homepage Reddit (Experiment 3)',
  path: '/l/UaE9I6q9d',
  redirect: {
    name: 'solution',
    query: {
      utm_campaign: 'VSCode_Homepage_E3',
      utm_medium: 'social_media_post',
      utm_source: 'reddit'
    }
  }
}, {
  name: 'UTM Homepage Reddit (Experiment 4)',
  path: '/l/j73-x-KMd',
  redirect: {
    name: 'solution',
    query: {
      utm_campaign: 'VSCode_Homepage_E4',
      utm_medium: 'social_media_post',
      utm_source: 'reddit'
    }
  }
}, {
  name: 'UTM Desktop App Reddit (Experiment 5)',
  path: '/l/SOVjt4L5w',
  redirect: {
    name: 'solution',
    query: {
      utm_campaign: 'DesktopApp_E5',
      utm_medium: 'social_media_post',
      utm_source: 'reddit'
    }
  }
}]
