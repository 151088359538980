export default [{
  name: 'UTM IDE Plugin LinkedIn (Experiment 1)',
  path: '/l/1dc1f497',
  redirect: {
    name: 'solution',
    query: {
      utm_campaign: 'IDE_Plugin',
      utm_medium: 'social_media_post',
      utm_source: 'linkedin'
    }
  }
}, {
  name: 'UTM VSCode Marketplace Download LinkedIn (Experiment 2)',
  path: '/l/59f1cc1b',
  redirect: {
    name: 'solution',
    query: {
      utm_campaign: 'VSCode_Marketplace_Download_E2',
      utm_medium: 'social_media_post',
      utm_source: 'linkedin'
    }
  }
}, {
  name: 'UTM Homepage LinkedIn (Experiment 3)',
  path: '/l/rPdjLjmXn',
  redirect: {
    name: 'solution',
    query: {
      utm_campaign: 'VSCode_Homepage_E3',
      utm_medium: 'social_media_post',
      utm_source: 'linkedin'
    }
  }
}, {
  name: 'UTM Homepage LinkedIn (Experiment 4)',
  path: '/l/eVRw8UIWf',
  redirect: {
    name: 'solution',
    query: {
      utm_campaign: 'VSCode_Homepage_E4',
      utm_medium: 'social_media_post',
      utm_source: 'linkedin'
    }
  }
}, {
  name: 'UTM Desktop App LinkedIn (Experiment 5)',
  path: '/l/1ZJ7zmi6s',
  redirect: {
    name: 'solution',
    query: {
      utm_campaign: 'DesktopApp_E5',
      utm_medium: 'social_media_post',
      utm_source: 'linkedin'
    }
  }
}]
