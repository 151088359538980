export default [{
  name: 'UTM VSCode Plugin YCombinator News (Experiment 2)',
  path: '/l/91aad1a6',
  redirect: {
    name: 'solution',
    query: {
      utm_campaign: 'VSCode_Plugin_E2',
      utm_medium: 'social_media_post',
      utm_source: 'ycombinator_news'
    }
  }
}, {
  name: 'UTM Homepage YCombinator News (Experiment 3)',
  path: '/l/CSTs3TQd3',
  redirect: {
    name: 'solution',
    query: {
      utm_campaign: 'VSCode_Homepage_E3',
      utm_medium: 'social_media_post',
      utm_source: 'ycombinator_news'
    }
  }
}, {
  name: 'UTM Homepage YCombinator News (Experiment 4)',
  path: '/l/JHRSX_817',
  redirect: {
    name: 'solution',
    query: {
      utm_campaign: 'VSCode_Homepage_E4',
      utm_medium: 'social_media_post',
      utm_source: 'ycombinator_news'
    }
  }
}, {
  name: 'UTM Desktop App YCombinator News (Experiment 5)',
  path: '/l/OhwjieuuK',
  redirect: {
    name: 'solution',
    query: {
      utm_campaign: 'DesktopApp_E5',
      utm_medium: 'social_media_post',
      utm_source: 'ycombinator_news'
    }
  }
}]
