export default [{
  name: 'UTM IDE Plugin Direct Message (Experiment 1)',
  path: '/l/47898901',
  redirect: {
    name: 'solution',
    query: {
      utm_campaign: 'IDE_Plugin',
      utm_medium: 'direct_message',
      utm_source: 'staff'
    }
  }
}, {
  name: 'UTM VSCode Marketplace Download Message (Experiment 2)',
  path: '/l/78c29036',
  redirect: {
    name: 'solution',
    query: {
      utm_campaign: 'VSCode_Marketplace_Download_E2',
      utm_medium: 'direct_message',
      utm_source: 'staff'
    }
  }
}, {
  name: 'UTM VSCode Marketplace Link',
  path: '/l/630cbe8f',
  redirect: {
    name: 'solution',
    query: {
      utm_medium: 'marketplace_link',
      utm_source: 'marketplace.visualstudio.com'
    }
  }
}, {
  name: 'UTM IcePanel Docs Link',
  path: '/l/dE48r3egR',
  redirect: {
    name: 'solution',
    query: {
      utm_campaign: 'IcePanel_Docs_Link',
      utm_medium: 'link',
      utm_source: 'icepanel_docs'
    }
  }
}, {
  name: 'UTM LinkedIn Advert (Experiment 3)',
  path: '/l/Rq7yuK9bN',
  redirect: {
    name: 'solution',
    query: {
      utm_campaign: 'VSCode_Homepage_E3',
      utm_medium: 'advert_link',
      utm_source: 'linkedin'
    }
  }
}, {
  name: 'UTM IcePanel Docs Navbar Link',
  path: '/l/dE48r3egR',
  redirect: {
    name: 'solution',
    query: {
      utm_medium: 'navbar_link',
      utm_source: 'docs.icepanel.io'
    }
  }
}]
