
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import PostAuthorAvatar from '@/components/post-author-avatar.vue'
import { TrackingModule } from '@/store'

import posts from '../../../posts/posts.json'
import * as analytics from '../../helpers/analytics'

@Component({
  components: {
    PostAuthorAvatar
  },
  metaInfo: {
    link: [
      {
        href: 'https://icepanel.io/blog/', rel: 'canonical'
      }
    ],
    meta: [
      { content: 'blog,posts,icepanel', name: 'keywords', vmid: 'keywords' },
      { content: 'https://icepanel.io/blog', name: 'og:url', vmid: 'og:url' },
      { content: 'Posts | IcePanel Blog', name: 'og:title', vmid: 'og:title' },
      { content: 'Posts | IcePanel Blog', name: 'twitter:title', vmid: 'twitter:title' },
      { content: 'Latest posts from the IcePanel blog', name: 'description', vmid: 'description' },
      { content: 'Latest posts from the IcePanel blog', name: 'og:description', vmid: 'og:description' },
      { content: 'Latest posts from the IcePanel blog', name: 'twitter:description', vmid: 'twitter:description' }
    ],
    title: 'Posts | IcePanel Blog'
  },
  name: 'blog'
})
export default class extends Vue {
  trackingModule = getModule(TrackingModule, this.$store)

  posts = posts.sort((a, b) => Number(new Date(b.metadata.date)) - Number(new Date(a.metadata.date)))

  get tracking () {
    return this.trackingModule.enabled
  }

  @Watch('tracking')
  onTrackingChanged (tracking: boolean, prevTracking: boolean) {
    if (tracking && !prevTracking) {
      analytics.blogScreen.track(this)
    }
  }

  mounted () {
    analytics.blogScreen.track(this)
  }
}
