import { SupportMessageRequired, SupportService } from '@icepanel/platform-api-client'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

export interface ISupportModule {}

const name = 'support'

@Module({
  name,
  namespaced: true
})
export class SupportModule extends VuexModule {
  static namespace = name

  message = ''

  @Mutation
  setMessage (message: string) {
    this.message = message
  }

  @Action({ rawError: true })
  async supportMessageCreate (contact: SupportMessageRequired) {
    await SupportService.supportMessageCreate(contact)
  }
}
