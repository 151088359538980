
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { TrackingModule } from '@/store'

import jobs from '../../../jobs/jobs.json'
import Astronaut from '../../components/astronaut.vue'
import * as analytics from '../../helpers/analytics'

@Component({
  components: {
    Astronaut
  },
  metaInfo: {
    link: [
      {
        href: 'https://icepanel.io/careers/', rel: 'canonical'
      }
    ],
    meta: [
      { content: 'careers,jobs,icepanel', name: 'keywords', vmid: 'keywords' },
      { content: 'https://icepanel.io/careers', name: 'og:url', vmid: 'og:url' },
      { content: 'Jobs | IcePanel Careers', name: 'og:title', vmid: 'og:title' },
      { content: 'Jobs | IcePanel Careers', name: 'twitter:title', vmid: 'twitter:title' },
      { content: 'Latest jobs from IcePanel careers', name: 'description', vmid: 'description' },
      { content: 'Latest jobs from IcePanel careers', name: 'og:description', vmid: 'og:description' },
      { content: 'Latest jobs from IcePanel careers', name: 'twitter:description', vmid: 'twitter:description' }
    ],
    title: 'Jobs | IcePanel Careers'
  },
  name: 'careers'
})
export default class extends Vue {
  trackingModule = getModule(TrackingModule, this.$store)

  jobs = jobs

  get tracking () {
    return this.trackingModule.enabled
  }

  get headlines () {
    return [
      '🙋 Independence to build our way',
      '🛠️ Build simple and exceptional experiences',
      '🧊 Transparency and openness',
      '💡 Stay humble and explore all ideas',
      '💩 No bullshit, have fun'
    ]
  }

  @Watch('tracking')
  onTrackingChanged (tracking: boolean, prevTracking: boolean) {
    if (tracking && !prevTracking) {
      analytics.careersScreen.track(this)
    }
  }

  mounted () {
    analytics.careersScreen.track(this)
  }
}
