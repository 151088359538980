
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import { NavigationItem, navigationItems } from '../helpers/navigation-items'

@Component({
  name: 'NavBarSmall'
})
export default class extends Vue {
  @Prop({ type: Boolean }) showHome!: boolean
  @Prop({ type: Boolean }) showTabs!: boolean

  drawer = false
  expandedTab: NavigationItem | null = null
  navigationItems = navigationItems

  toggleTab (tab: NavigationItem) {
    if (this.expandedTab && this.expandedTab.text === tab?.text) {
      this.expandedTab = null
      return
    }

    this.expandedTab = tab
  }

  getSubItems (tab: NavigationItem): NavigationItem[] {
    let items: NavigationItem[] = []
    if (tab.subItems) {
      items = items.concat(tab.subItems)
    }
    if (tab.subItemsCTA) {
      items.push(tab.subItemsCTA)
    }

    return items
  }

  refreshAppBar () {
    this.$emit('refresh-app-bar')
  }
}
