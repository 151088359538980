
import Vue from 'vue'
import Component from 'vue-class-component'

interface ShareTab {
  text: string
  icon?: string
  url: string
  isIcePanel?: boolean
}

@Component({
  name: 'Examples'
})
export default class extends Vue {
  loadFrame = false
  tabs: ShareTab[] = [
    { isIcePanel: true, text: 'IcePanel', url: 'https://s.icepanel.io/poAqz4n9S6fOW3/SdSI' },
    { icon: '$fas-shopping-cart', text: 'E-Commerce', url: 'https://s.icepanel.io/5OKxXHfTnxxlso/dZkN' },
    { icon: '$fas-code', text: 'Low-code', url: 'https://s.icepanel.io/S521pDUEELeL9V/oyVo' },
    { icon: '$fas-hotel', text: 'Marketplace', url: 'https://s.icepanel.io/VgpDRTvGLyNobm/gezU' },
    { icon: '$custom-building-columns', text: 'Banking', url: 'https://s.icepanel.io/Zwaz3mpkZKn7dR/Lymv' },
    { icon: '$fas-rss', text: 'RSS feed', url: 'https://s.icepanel.io/OpV6LEKHxXNdLk/A8Ng' }
  ]

  selectedTab: ShareTab = this.tabs[0]

  selectTab (tab: ShareTab) {
    this.selectedTab = tab
  }

  onFrameIntersect (entries: any[]) {
    if (entries[0]?.isIntersecting) {
      this.loadFrame = true
    }
  }
}
