
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

@Component({
  name: 'Mountain'
})
export default class extends Vue {
  viewWidth = 0

  get ratio () {
    return 5.159929078
  }

  get width () {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs': return 610 + 60
      case 'sm': return 960 + 60
      case 'md': return 1280 + 60
      case 'lg': return 1920 + 60
      default: return this.viewWidth + 60
    }
  }

  get height () {
    return this.width / this.ratio
  }

  resizeListener?: () => void

  mounted () {
    this.resize()
    this.resizeListener = this.resize.bind(this)
    window.addEventListener('resize', this.resizeListener)

    this.$emit('resize', { height: this.height, width: this.width })
  }

  destroyed () {
    if (this.resizeListener) {
      window.removeEventListener('resize', this.resizeListener)
    }
  }

  @Watch('height')
  onHeightChanged (height: number) {
    this.$emit('resize', { height, width: this.width })
  }

  @Watch('width')
  onWidthChanged (width: number) {
    this.$emit('resize', { height: this.height, width })
  }

  resize () {
    this.viewWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
  }
}
