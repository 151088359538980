import { OrganizationBillingCurrency } from '@icepanel/platform-api-client'

export const CURRENCIES: { symbol: string, id: OrganizationBillingCurrency, image: string }[] = [
  {
    id: 'usd',
    image: require('@/assets/flags/us.png'),
    symbol: '$'
  },
  {
    id: 'cad',
    image: require('@/assets/flags/ca.png'),
    symbol: '$'
  },
  {
    id: 'eur',
    image: require('@/assets/flags/eu.png'),
    symbol: '€'
  },
  {
    id: 'gbp',
    image: require('@/assets/flags/gb.png'),
    symbol: '£'
  }
]
