
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Aurora from '@/components/aurora.vue'
import RoadmapCard, { RoadmapItem } from '@/components/roadmap-card.vue'
import * as analytics from '@/helpers/analytics'
import { SupportModule, TrackingModule } from '@/store'

import roadmap from '../../res/roadmap.json'

@Component({
  components: {
    Aurora,
    RoadmapCard
  },
  metaInfo: {
    meta: [
      { content: 'public,roadmap,suggestion,product,idea,community,upcoming,new,backlog,feature,request', name: 'keywords', vmid: 'keywords' },
      { content: 'https://icepanel.io/roadmap', name: 'og:url', vmid: 'og:url' },
      { content: 'IcePanel | Roadmap | Explain complex software systems', name: 'og:title', vmid: 'og:title' },
      { content: 'IcePanel | Roadmap | Explain complex software systems', name: 'twitter:title', vmid: 'twitter:title' },
      { content: 'Transparency is a core part of IcePanel... and that includes what we’re working on!', name: 'og:description', vmid: 'og:description' },
      { content: 'Transparency is a core part of IcePanel... and that includes what we’re working on!', name: 'description', vmid: 'description' },
      { content: 'Transparency is a core part of IcePanel... and that includes what we’re working on!', name: 'twitter:description', vmid: 'twitter:description' }
    ],
    title: 'IcePanel | Roadmap | Explain complex software systems'
  },
  name: 'Roadmap'
})
export default class extends Vue {
  supportModule = getModule(SupportModule, this.$store)
  trackingModule = getModule(TrackingModule, this.$store)

  @Ref() readonly headerRef!: HTMLElement

  error = ''
  items = roadmap as RoadmapItem[]

  get exploringItems () {
    return this.items.filter(o => o.state === 'Backlog' || o.state === 'Todo').sort((a, b) => a.who.length > b.who.length ? -1 : 1)
  }

  get inProgressItems () {
    return this.items.filter(o => o.state === 'In Progress')
  }

  get deliveredItems () {
    return this.items.filter(o => o.state === 'Done' || o.state === 'Contacted').sort((a, b) => a.release && b.release ? b.release.localeCompare(a.release) : 0)
  }

  get tracking () {
    return this.trackingModule.enabled
  }

  @Watch('tracking')
  onTrackingChanged (tracking: boolean, prevTracking: boolean) {
    if (tracking && !prevTracking) {
      analytics.roadmapScreen.track(this)
    }
  }

  mounted () {
    analytics.roadmapScreen.track(this)
  }

  feedbackLink () {
    window.open('https://forms.gle/g3KdAUps2dXmG7Y67', '_blank')?.focus()

    analytics.feedbackLink.track(this)
  }

  requestFeature () {
    this.supportModule.setMessage('I\'d like to make a feature request.')

    this.$router.push({
      name: 'contact'
    })
  }
}
