export default [{
  name: 'UTM VSCode Plugin (Experiment 2)',
  path: '/l/7c9e9133',
  redirect: {
    name: 'solution',
    query: {
      utm_campaign: 'VSCode_Plugin_E2',
      utm_medium: 'chatroom_message',
      utm_source: 'kubernetes_slack'
    }
  }
}, {
  name: 'UTM Homepage Slack (Experiment 3)',
  path: '/l/xFLtqX3lK',
  redirect: {
    name: 'solution',
    query: {
      utm_campaign: 'VSCode_Homepage_E3',
      utm_medium: 'chatroom_message',
      utm_source: 'kubernetes_slack'
    }
  }
}]
