
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { SupportModule } from '@/store'

import * as analytics from '../helpers/analytics'

export interface RoadmapItem {
  completedAt?: Date
  id: string
  number: number
  release?: string
  state: 'Backlog' | 'Todo' | 'In Progress' | 'Done' | 'Canceled' | 'Duplicate' | 'Contacted'
  title: string
  who: string[]
}

@Component({
  name: 'RoadmapCard'
})
export default class extends Vue {
  supportModule = getModule(SupportModule, this.$store)

  @Prop() readonly item!: RoadmapItem

  get color () {
    return this.item?.state === 'In Progress' ? '#353C3D' : 'primary'
  }

  get border () {
    if (this.item?.state === 'In Progress') {
      return 'var(--v-accent-base)'
    } else if (this.item?.state === 'Done' || this.item?.state === 'Contacted') {
      return '#0DBF6F'
    }
  }

  roadmapRequest (item: RoadmapItem) {
    analytics.roadmapRequest.track(this, {
      roadmapRequestId: item.number,
      roadmapRequestTitle: item.title
    })

    this.supportModule.setMessage(`I'd like to add my vote to roadmap request REQ-${item.number}.\n${item.title}.`)

    this.$router.push({
      name: 'contact'
    })
  }
}
