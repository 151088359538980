
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { VueTyper } from 'vue-typer'
import { getModule } from 'vuex-module-decorators'

import Aurora from '@/components/aurora.vue'
import CustomerQuotes from '@/components/customer-quotes.vue'
import Iceberg from '@/components/iceberg.vue'
import Video from '@/components/video.vue'
import { getDemoLinks } from '@/helpers/demoLinks'
import { TrackingModule } from '@/store'

import * as analytics from '../helpers/analytics'

const CHECK_ICON = {
  icon: '$fas-check',
  iconColor: 'grey lighten-2'
} as const

const CROSS_ICON = {
  icon: '$custom-solid-horizontal-rule',
  iconColor: 'grey lighten-4'
} as const

const UNLIMITED_TEXT = {
  text: 'Unlimited'
} as const

@Component({
  components: {
    Aurora,
    CustomerQuotes,
    Iceberg,
    Video,
    VueTyper
  },
  metaInfo: {
    meta: [
      { content: 'feature,architects,c4,model,structured,diagram,docs,share,system,software,architectures,team,interactive,trust,visual,living,sequence', name: 'keywords', vmid: 'keywords' },
      { content: 'https://icepanel.io/features', name: 'og:url', vmid: 'og:url' },
      { content: 'IcePanel | Not your basic diagramming tool', name: 'og:title', vmid: 'og:title' },
      { content: 'IcePanel | Not your basic diagramming tool', name: 'twitter:title', vmid: 'twitter:title' },
      { content: 'Modelling gives you deeper insights into your technical design with less maintenance. Align on technical decisions across your software engineering and product teams.', name: 'description', vmid: 'description' },
      { content: 'Modelling gives you deeper insights into your technical design with less maintenance. Align on technical decisions across your software engineering and product teams.', name: 'og:description', vmid: 'og:description' },
      { content: 'Modelling gives you deeper insights into your technical design with less maintenance. Align on technical decisions across your software engineering and product teams.', name: 'twitter:description', vmid: 'twitter:description' }
    ],
    title: 'IcePanel | Not your basic diagramming tool'
  },
  name: 'Features'
})
export default class extends Vue {
  trackingModule = getModule(TrackingModule, this.$store)

  hoveredCard: number | null = null

  get appUrl () {
    return `https://app.icepanel.${process.env.VUE_APP_ICE_ENV === 'production' ? 'io' : 'dev'}`
  }

  get demoLinks () {
    return getDemoLinks()
  }

  get design () {
    return [
      'Effortlessly create structured system designs with your team',
      'Incremental and future architecture with a consistent language'
    ]
  }

  get explain () {
    return [
      'Communicate architecture in a tool accessible to your whole team',
      'Visually share knowledge with perspectives for each conversation'
    ]
  }

  get maintain () {
    return [
      'Link your design to reality and be notified when it’s out of date',
      'Sync model updates across your diagrams for living docs'
    ]
  }

  get features () {
    return [
      {
        icon: '$fas-sitemap',
        id: 'modelling',
        list: [
          {
            description: 'Zoom in and out of the C4 model diagram levels, easily exploring the big picture to the details.',
            imageAspectRatio: 1.777,
            imageGif: require('../assets/features/zoom.gif'),
            imageJpeg: require('../assets/features/jpeg/zoom.jpg'),
            plans: {
              free: {
                text: '100 model objects'
              },
              growth: UNLIMITED_TEXT,
              isolation: UNLIMITED_TEXT
            },
            text: 'Model and diagrams'
          },
          {
            description: 'A landscape uses a single model to draw many diagrams; visualizing the systems spanning across your organization.',
            imageAspectRatio: 1.777,
            imageJpeg: require('../assets/features/landscape.webp'),
            plans: {
              free: {
                text: 'Up to 2'
              },
              growth: {
                text: 'Unlimited'
              },
              isolation: {
                text: 'Unlimited'
              }
            },
            text: 'Landscapes'
          },
          {
            description: 'Groups for organizing many diagrams by subject.',
            imageAspectRatio: 1.839,
            imageGif: require('../assets/features/diagram-groups.gif'),
            imageJpeg: require('../assets/features/jpeg/diagram-groups.jpg'),
            plans: {
              free: CROSS_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Diagram groups'
          },
          {
            description: 'Domains organize your landscape\'s systems into logical groups aligned with your business structure. You can share objects in diagrams between domains.',
            imageAspectRatio: 1.777,
            imageGif: require('../assets/features/domains.gif'),
            imageJpeg: require('../assets/features/jpeg/domains.jpg'),
            plans: {
              free: CROSS_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Domains'
          },
          {
            description: 'Attach rich documentation to model objects, connections and diagrams to give more context to your audience - supports Markdown.',
            imageAspectRatio: 1.777,
            imageGif: require('../assets/features/documentation.gif'),
            imageJpeg: require('../assets/features/jpeg/documentation.jpg'),
            plans: {
              free: CHECK_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Documentation'
          },
          {
            description: 'Updates to your model push change across all diagrams, making design refactoring effortless.',
            imageAspectRatio: 1.777,
            imageGif: require('../assets/features/sync-model-changes.gif'),
            imageJpeg: require('../assets/features/jpeg/sync-model-changes.jpg'),
            plans: {
              free: CHECK_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Sync model changes'
          },
          {
            description: 'Visually follow the evolution of your system design across time.',
            imageAspectRatio: 1.777,
            imageGif: require('../assets/features/versions.gif'),
            imageJpeg: require('../assets/features/jpeg/versions.jpg'),
            plans: {
              free: {
                text: 'View last 3 versions'
              },
              growth: UNLIMITED_TEXT,
              isolation: UNLIMITED_TEXT
            },
            text: 'Versions'
          },
          {
            description: 'Revert back to a point in the past with version reverting.',
            imageAspectRatio: 1.777,
            imageGif: require('../assets/features/version-revert.gif'),
            imageJpeg: require('../assets/features/jpeg/version-revert.jpg'),
            plans: {
              free: CROSS_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Version revert'
          },
          {
            description: 'Manage the lifecycle of each domain, system, app and store independently with hierarchical versioning.',
            imageAspectRatio: 1.777,
            imageGif: require('../assets/features/version-objects.gif'),
            imageJpeg: require('../assets/features/jpeg/version-objects.jpg'),
            plans: {
              free: CROSS_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Versions for objects'
          },
          {
            description: 'Export your objects, connections, diagrams, flows, tags and teams in PNG, PDF, JSON or CSV formats',
            imageAspectRatio: 1.777,
            imageJpeg: require('../assets/features/exports.webp'),
            plans: {
              free: CHECK_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Exports'
          }
        ],
        text: 'Modelling'
      },
      {
        icon: '$fas-layer-group',
        id: 'visual-storytelling',
        list: [
          {
            description: 'Dynamically overlay different perspectives on your existing diagrams and filter your model by tags.',
            imageAspectRatio: 1.777,
            imageGif: require('../assets/features/tags.gif'),
            imageJpeg: require('../assets/features/jpeg/tags.jpg'),
            plans: {
              free: {
                text: '2 tag groups'
              },
              growth: UNLIMITED_TEXT,
              isolation: UNLIMITED_TEXT
            },
            text: 'Tags'
          },
          {
            description: 'Dynamically overlay sequences of messages on existing diagrams to showcase technical, user or business flows.',
            imageAspectRatio: 1.777,
            imageGif: require('../assets/features/flows.gif'),
            imageJpeg: require('../assets/features/jpeg/flows.jpg'),
            plans: {
              free: {
                text: '3 flows'
              },
              growth: UNLIMITED_TEXT,
              isolation: UNLIMITED_TEXT
            },
            text: 'Flows'
          },
          {
            description: 'Show alternate and parallel choices in a message flow',
            imageAspectRatio: 1.895,
            imageGif: require('../assets/features/flow-paths.gif'),
            imageJpeg: require('../assets/features/jpeg/flow-paths.jpg'),
            plans: {
              free: CROSS_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Flow paths'
          },
          {
            description: 'Add AWS, Azure, GCP and Kubernetes icons to visualize the technology choice of your apps and stores.',
            imageAspectRatio: 1.777,
            imageJpeg: require('../assets/features/cloud-icons.webp'),
            plans: {
              free: CHECK_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Cloud icons'
          },
          {
            description: 'Visualize the differences between versions of your system design and easily follow the evolution across time.',
            imageAspectRatio: 1.777,
            imageGif: require('../assets/features/versions.gif'),
            imageJpeg: require('../assets/features/jpeg/versions.jpg'),
            plans: {
              free: CHECK_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Version timeline'
          }
        ],
        text: 'Visual storytelling'
      },
      {
        icon: '$fas-calendar-check',
        id: 'up-to-date-checks',
        list: [
          {
            description: 'List important resources in your source control and quickly get to them - great for onboarding. We periodically check reality links and mark them as inaccurate if missing.',
            imageAspectRatio: 1.777,
            imageGif: require('../assets/features/reality-links.gif'),
            imageJpeg: require('../assets/features/jpeg/reality-links.jpg'),
            plans: {
              free: {
                text: '10 links - 1 per object'
              },
              growth: UNLIMITED_TEXT,
              isolation: UNLIMITED_TEXT
            },
            text: 'Links'
          },
          {
            description: 'Link to important resources in your source control and quickly get to them - great for onboarding. We periodically check these links and mark them as inaccurate if missing.\n\nSupports: GitHub, GitLab, BitBucket Server and Azure DevOps',
            icons: [
              '$fab-github',
              '$fab-gitlab',
              '$fab-bitbucket',
              '$fab-windows'
            ],
            imageAspectRatio: 1.777,
            imageJpeg: require('../assets/features/code-repo-integration.webp'),
            plans: {
              free: CHECK_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Code repo integrations'
          },
          {
            description: 'See where your design drifts from reality and take action to resolve the inaccuracies.',
            imageAspectRatio: 1.777,
            imageJpeg: require('../assets/features/accuracy-score.webp'),
            plans: {
              free: CHECK_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Inaccuracy score'
          },
          {
            description: 'See where your documentation could be improved and resolve common diagramming mistakes.',
            imageAspectRatio: 1.777,
            imageJpeg: require('../assets/features/jpeg/recommendation-score.jpg'),
            plans: {
              free: CHECK_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Recommendation score'
          }
        ],
        text: 'Up-to-date checks'
      },
      {
        icon: '$fas-users',
        id: 'collaboration',
        list: [
          {
            description: 'Collaborate with your teammates and create a consistent shared knowledge base - better together.',
            imageAspectRatio: 1.776,
            imageGif: require('../assets/features/collaboration.gif'),
            imageJpeg: require('../assets/features/jpeg/collaboration.jpg'),
            plans: {
              free: {
                text: '1 - 5'
              },
              growth: {
                text: '1 - 50'
              },
              isolation: {
                text: '50+'
              }
            },
            text: 'Users'
          },
          {
            description: 'Share and embed interactive read-only links of your landscape and diagrams.',
            imageAspectRatio: 1.777,
            imageGif: require('../assets/features/share-links.gif'),
            imageJpeg: require('../assets/features/jpeg/share-links.jpg'),
            plans: {
              free: {
                text: 'Public'
              },
              growth: {
                text: 'Password + SSO'
              },
              isolation: {
                text: 'Password + SSO'
              }
            },
            text: 'Share links'
          },
          {
            description: 'Share interactive frozen versions of your design as it existed in the past.',
            imageAspectRatio: 1.777,
            imageGif: require('../assets/features/share-link-versions.gif'),
            imageJpeg: require('../assets/features/jpeg/share-link-versions.jpg'),
            plans: {
              free: CROSS_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Share links for versions'
          },
          {
            description: 'Share who your system experts are by assigning owners to model objects and contact them easily.',
            imageAspectRatio: 1.777,
            imageGif: require('../assets/features/teams.gif'),
            imageJpeg: require('../assets/features/jpeg/teams.jpg'),
            plans: {
              free: CROSS_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Team ownership for objects'
          },
          {
            description: 'See a detailed logs of the changes happening in your landscape.',
            imageAspectRatio: 1.777,
            imageGif: require('../assets/features/history-logs.gif'),
            imageJpeg: require('../assets/features/jpeg/history-logs.jpg'),
            plans: {
              free: {
                text: 'Last 30 days'
              },
              growth: UNLIMITED_TEXT,
              isolation: UNLIMITED_TEXT
            },
            text: 'History logs'
          },
          {
            description: 'Collaborate in real time with your colleagues.',
            imageAspectRatio: 1.776,
            imageGif: require('../assets/features/collaboration.gif'),
            imageJpeg: require('../assets/features/jpeg/collaboration.jpg'),
            plans: {
              free: CHECK_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Real time'
          }
        ],
        text: 'Collaboration'
      }
    ]
  }

  get tracking () {
    return this.trackingModule.enabled
  }

  @Watch('tracking')
  onTrackingChanged (tracking: boolean, prevTracking: boolean) {
    if (tracking && !prevTracking) {
      analytics.featuresScreen.track(this)
    }
  }

  mounted () {
    analytics.featuresScreen.track(this)
  }

  featuresVideoPlay () {
    analytics.featuresVideoPlay.track(this)
  }

  featuresVideoEnd () {
    analytics.featuresVideoEnd.track(this)
  }

  getStartedLink (getStartedPosition: 'top' | 'bottom') {
    analytics.getStartedLink.track(this, {
      getStartedPosition
    })
  }

  seeInActionLink (seeInActionType: 'model' | 'tags' | 'flows' | 'reality-linking') {
    analytics.seeInActionLink.track(this, {
      seeInActionType
    })
  }

  videoLink (videoType: 'modelling' | 'c4-model') {
    analytics.videoLink.track(this, {
      videoType
    })
  }

  bookDemoLink (bookDemoPosition: 'top' | 'bottom') {
    analytics.bookDemoLink.track(this, {
      bookDemoPosition
    })
  }
}
