
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

type VideoName = 'collaboration' | 'overlay' | 'reality-link' | 'structure'

@Component({
  name: 'Video'
})
export default class extends Vue {
  @Prop() readonly name!: VideoName

  get source () {
    switch (this.name) {
      case 'collaboration': return require('@/assets/videos/collaboration.mp4')
      case 'overlay': return require('@/assets/videos/overlay.mp4')
      case 'reality-link': return require('@/assets/videos/reality-link.mp4')
      case 'structure': return require('@/assets/videos/structure.mp4')
    }
  }
}
