import { NewsService } from '@icepanel/platform-api-client'
import { Action, Module, VuexModule } from 'vuex-module-decorators'

export interface INewsModule {}

const name = 'news'

@Module({
  name,
  namespaced: true
})
export class NewsModule extends VuexModule {
  static namespace = name

  @Action({ rawError: true })
  async newsSubscribe (contact: Parameters<typeof NewsService.newsSubscribe>[0]) {
    await NewsService.newsSubscribe(contact)
  }
}
