import { library } from '@fortawesome/fontawesome-svg-core'
import * as fabBitbucket from '@fortawesome/free-brands-svg-icons/faBitbucket'
import * as fabDev from '@fortawesome/free-brands-svg-icons/faDev'
import * as fabFacebook from '@fortawesome/free-brands-svg-icons/faFacebook'
import * as fabFacebookF from '@fortawesome/free-brands-svg-icons/faFacebookF'
import * as fabGithub from '@fortawesome/free-brands-svg-icons/faGithub'
import * as fabGitlab from '@fortawesome/free-brands-svg-icons/faGitlab'
import * as fabLinkedin from '@fortawesome/free-brands-svg-icons/faLinkedin'
import * as fabLinkedinIn from '@fortawesome/free-brands-svg-icons/faLinkedinIn'
import * as fabMedium from '@fortawesome/free-brands-svg-icons/faMedium'
import * as fasMicrosoft from '@fortawesome/free-brands-svg-icons/faMicrosoft'
import * as fabTwitter from '@fortawesome/free-brands-svg-icons/faTwitter'
import * as fabTwitterSquare from '@fortawesome/free-brands-svg-icons/faTwitterSquare'
import * as fabWindows from '@fortawesome/free-brands-svg-icons/faWindows'
import * as farArrowAltCircleDown from '@fortawesome/free-regular-svg-icons/faArrowAltCircleDown'
import * as farCalendar from '@fortawesome/free-regular-svg-icons/faCalendar'
import * as farSquare from '@fortawesome/free-regular-svg-icons/faSquare'
import * as fasAngleRight from '@fortawesome/free-solid-svg-icons/faAngleRight'
import * as fasBalanceScale from '@fortawesome/free-solid-svg-icons/faBalanceScale'
import * as fasBars from '@fortawesome/free-solid-svg-icons/faBars'
import * as fasBolt from '@fortawesome/free-solid-svg-icons/faBolt'
import * as fasBook from '@fortawesome/free-solid-svg-icons/faBook'
import * as fasBoxOpen from '@fortawesome/free-solid-svg-icons/faBoxOpen'
import * as fasBug from '@fortawesome/free-solid-svg-icons/faBug'
import * as fasBuilding from '@fortawesome/free-solid-svg-icons/faBuilding'
import * as fasCalculator from '@fortawesome/free-solid-svg-icons/faCalculator'
import * as fasCalendarCheck from '@fortawesome/free-solid-svg-icons/faCalendarCheck'
import * as fasCaretDown from '@fortawesome/free-solid-svg-icons/faCaretDown'
import * as fasCaretLeft from '@fortawesome/free-solid-svg-icons/faCaretLeft'
import * as fasChalkboardTeacher from '@fortawesome/free-solid-svg-icons/faChalkboardTeacher'
import * as fasCheck from '@fortawesome/free-solid-svg-icons/faCheck'
import * as fasCheckCircle from '@fortawesome/free-solid-svg-icons/faCheckCircle'
import * as fasCheckSquare from '@fortawesome/free-solid-svg-icons/faCheckSquare'
import * as fasChevronDown from '@fortawesome/free-solid-svg-icons/faChevronDown'
import * as fasChevronLeft from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import * as fasChevronRight from '@fortawesome/free-solid-svg-icons/faChevronRight'
import * as fasChevronUp from '@fortawesome/free-solid-svg-icons/faChevronUp'
import * as fasCircle from '@fortawesome/free-solid-svg-icons/faCircle'
import * as fasClipboardList from '@fortawesome/free-solid-svg-icons/faClipboardList'
import * as fasCloud from '@fortawesome/free-solid-svg-icons/faCloud'
import * as fasCode from '@fortawesome/free-solid-svg-icons/faCode'
import * as fasComment from '@fortawesome/free-solid-svg-icons/faComment'
import * as fasCookieBite from '@fortawesome/free-solid-svg-icons/faCookieBite'
import * as fasDatabase from '@fortawesome/free-solid-svg-icons/faDatabase'
import * as fasDesktop from '@fortawesome/free-solid-svg-icons/faDesktop'
import * as fasDownload from '@fortawesome/free-solid-svg-icons/faDownload'
import * as fasEdit from '@fortawesome/free-solid-svg-icons/faEdit'
import * as fasEllipsisH from '@fortawesome/free-solid-svg-icons/faEllipsisH'
import * as fasEnvelope from '@fortawesome/free-solid-svg-icons/faEnvelope'
import * as fasExclamationTriangle from '@fortawesome/free-solid-svg-icons/faExclamationTriangle'
import * as fasExternalLinkAlt from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt'
import * as fasFile from '@fortawesome/free-solid-svg-icons/faFile'
import * as fasGraduationCap from '@fortawesome/free-solid-svg-icons/faGraduationCap'
import * as fasHandHoldingHeart from '@fortawesome/free-solid-svg-icons/faHandHoldingHeart'
import * as fasHandPointer from '@fortawesome/free-solid-svg-icons/faHandPointer'
import * as fasHotel from '@fortawesome/free-solid-svg-icons/faHotel'
import * as fasHourglassHalf from '@fortawesome/free-solid-svg-icons/faHourglassHalf'
import * as fasInfoCircle from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import * as fasLaptop from '@fortawesome/free-solid-svg-icons/faLaptop'
import * as fasLayerGroup from '@fortawesome/free-solid-svg-icons/faLayerGroup'
import * as fasList from '@fortawesome/free-solid-svg-icons/faList'
import * as fasListOl from '@fortawesome/free-solid-svg-icons/faListOl'
import * as fasLock from '@fortawesome/free-solid-svg-icons/faLock'
import * as fasPlay from '@fortawesome/free-solid-svg-icons/faPlay'
import * as fasPlus from '@fortawesome/free-solid-svg-icons/faPlus'
import * as fasQuoteLeft from '@fortawesome/free-solid-svg-icons/faQuoteLeft'
import * as fasQuoteRight from '@fortawesome/free-solid-svg-icons/faQuoteRight'
import * as fasRss from '@fortawesome/free-solid-svg-icons/faRss'
import * as fasShare from '@fortawesome/free-solid-svg-icons/faShare'
import * as fasShoppingCart from '@fortawesome/free-solid-svg-icons/faShoppingCart'
import * as fasSitemap from '@fortawesome/free-solid-svg-icons/faSitemap'
import * as fasTimes from '@fortawesome/free-solid-svg-icons/faTimes'
import * as fasToolbox from '@fortawesome/free-solid-svg-icons/faToolbox'
import * as fasTrophy from '@fortawesome/free-solid-svg-icons/faTrophy'
import * as fasUser from '@fortawesome/free-solid-svg-icons/faUser'
import * as fasUserAlt from '@fortawesome/free-solid-svg-icons/faUserAlt'
import * as fasUserCircle from '@fortawesome/free-solid-svg-icons/faUserCircle'
import * as fasUserEdit from '@fortawesome/free-solid-svg-icons/faUserEdit'
import * as fasUserLock from '@fortawesome/free-solid-svg-icons/faUserLock'
import * as faUserPlus from '@fortawesome/free-solid-svg-icons/faUserPlus'
import * as fasUsers from '@fortawesome/free-solid-svg-icons/faUsers'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const icons = [
  fabBitbucket.definition,
  fabDev.definition,
  fabFacebook.definition,
  fabFacebookF.definition,
  fabGithub.definition,
  fabGitlab.definition,
  fabLinkedin.definition,
  fabLinkedinIn.definition,
  fabMedium.definition,
  fabTwitter.definition,
  fabTwitterSquare.definition,
  fabWindows.definition,
  farArrowAltCircleDown.definition,
  farCalendar.definition,
  farSquare.definition,
  fasAngleRight.definition,
  fasBalanceScale.definition,
  fasBars.definition,
  fasBolt.definition,
  fasBook.definition,
  fasBoxOpen.definition,
  fasBug.definition,
  fasBuilding.definition,
  fasCalculator.definition,
  fasCalendarCheck.definition,
  fasCaretDown.definition,
  fasCaretLeft.definition,
  fasChalkboardTeacher.definition,
  fasCheck.definition,
  fasCheckCircle.definition,
  fasCheckSquare.definition,
  fasChevronDown.definition,
  fasChevronLeft.definition,
  fasChevronRight.definition,
  fasChevronUp.definition,
  fasCircle.definition,
  fasClipboardList.definition,
  fasCloud.definition,
  fasCode.definition,
  fasComment.definition,
  fasCookieBite.definition,
  fasDatabase.definition,
  fasDesktop.definition,
  fasDownload.definition,
  fasEdit.definition,
  fasEllipsisH.definition,
  fasEnvelope.definition,
  fasExclamationTriangle.definition,
  fasExternalLinkAlt.definition,
  fasFile.definition,
  fasGraduationCap.definition,
  fasHandHoldingHeart.definition,
  fasHandPointer.definition,
  fasHotel.definition,
  fasHourglassHalf.definition,
  fasInfoCircle.definition,
  fasLaptop.definition,
  fasLayerGroup.definition,
  fasList.definition,
  fasListOl.definition,
  fasLock.definition,
  fasMicrosoft.definition,
  fasPlay.definition,
  fasPlus.definition,
  fasQuoteLeft.definition,
  fasQuoteRight.definition,
  fasRss.definition,
  fasShare.definition,
  fasShoppingCart.definition,
  fasSitemap.definition,
  fasTimes.definition,
  fasToolbox.definition,
  fasTrophy.definition,
  fasUser.definition,
  fasUserAlt.definition,
  fasUserCircle.definition,
  fasUserEdit.definition,
  fasUserLock.definition,
  fasUsers.definition,
  faUserPlus.definition
] as const

const values: any = {}
icons.forEach(definition => {
  library.add(definition)

  values[`${definition.prefix}-${definition.iconName}`] = {
    component: FontAwesomeIcon,
    props: {
      icon: [definition.prefix, definition.iconName]
    }
  }
})

export default values
